<template>
  <div class="home-simplified actor-simplified scrollable" @scroll="onScroll">
    <basic-simplified-hero
      v-if="enableBanner"
      :title="isProductsPage ? onboardingTextConfig.productsSimplifiedTitle : onboardingTextConfig.actorsSimplifiedTitle"
      :subtitle="isProductsPage ? onboardingTextConfig.productsSimplifiedSubtitle : onboardingTextConfig.actorsSimplifiedSubtitle"
    />

    <Container style="flex-grow: 1">
      <h2 class="h2">{{
          isProductsPage ? $t('shp_products_title', { products: productsLabel }).toUpperCase() : onboardingTextConfig.actorsPageTitle.toUpperCase()
        }}<span
          v-if="totalActorsCount !== null"
        > ({{ totalActorsCount ? totalActorsCount : '0' }})</span></h2>
      <div v-if="!isMobile" class="facet-filters">
        <template v-for="(facet, index) in limitedFacetCounts" :key="'facet' + index">
          <div
            class="facet-filter"
            :class="{'show-all': showAll[facet.parent_id ? facet.name + facet.parent_id : facet.name]}"
          >
            <div
              class="facet-filter__title"
              v-tooltip.top="getFacetTitleLabel(facet)"
            >
              {{ getFacetTitleLabel(facet) }}
            </div>
            <div class="facet-filter__facet">
              <div style="display: inline-block">
                <ul
                  class="category-list"
                  :ref="facet.parent_id ? 'facet' + facet.name + facet.parent_id : 'facet' + facet.name"
                >
                  <li class="category category--active">
                    <button
                      :style="getCategoryButtonStyles(facet.name, null, facet.parent_id)"
                      :class="{disabled: isLoading}"
                      @click="handleClickClearAllKeywords(facet.name, facet.parent_id)"
                    >
                      {{ $t('categories_all') }}
                    </button>
                  </li>
                  <li
                    v-for="(facetItem, index) in facet.count"
                    class="category"
                    :key="'facet' + index"
                  >
                    <facet-filter-button
                      :disabled="isLoading"
                      :facetCounts="facetCounts"
                      :facetName="facet.name"
                      :facetLabel="facetItem.label"
                      :facetValue="facetItem.value"
                      :allow-toggle="true"
                      @click="handleSelectFacet(facet.name, facetItem.value)"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="facet-filter__show-more-container"
              v-show="hasExceededLengthLimit(facet.name, 52, facet.parent_id)"
              :key="componentKey + windowWidth"
            >
              <a
                class="facet-filter__show-more-link" href="#"
                @click="toggleShowAll(facet.parent_id ? facet.name + facet.parent_id : facet.name)"
              >{{
                  showAll[facet.parent_id ? facet.name + facet.parent_id : facet.name] ? $t('actor_detail_show_less') : $t('actor_detail_show_all')
                }}</a>
            </div>
          </div>
        </template>
        <div
          class="facet-filter facet-filter-sdg"
          :class="{'show-all': showAll['sustainability_goal']}"
          v-if="sdgFacetCount.length > 0 && availableFilterControls.includes('sustainability_goal')"
        >
          <div class="facet-filter__title">
            {{ $t('sustainability_goal') }}
          </div>
          <div class="facet-filter__facet">
            <div style="display: inline-block">
              <ul class="sdg-filters-container" :ref="'facet' + 'sustainability_goal'">
                <li
                  :title="option.label"
                  v-for="(option, index) in sdgFacetCount"
                  class="sdg-filter" :key="'sdg' + index"
                  v-tooltip.top="translatedSustainabilityGoals[getIndexForSdgLabel(option.label) - 1]"
                  :class="{ 'modal__body__active-filter-img': isSdgSelected(option.value), 'disabled': isLoading}"
                >
                  <img
                    :src="sdgImagesPath + getIndexForSdgLabel(option.label) + '.jpg'"
                    :title="translatedSustainabilityGoals[getIndexForSdgLabel(option.label) - 1]"
                    :alt="translatedSustainabilityGoals[getIndexForSdgLabel(option.label) - 1]"
                    @click="handleSelectFacet('sustainability_goal', option.value)"
                  >
                </li>
              </ul>
            </div>
          </div>
          <div
            class="facet-filter__show-more-container"
            v-show="hasExceededLengthLimit('sustainability_goal', 52)"
            :key="componentKey + windowWidth"
          >
            <a
              class="facet-filter__show-more-link" href="#"
              @click="toggleShowAll('sustainability_goal')"
            >{{
                showAll['sustainability_goal'] ? $t('actor_detail_show_less') : $t('actor_detail_show_all')
              }}</a>
          </div>
        </div>
        <template
          v-for="(keyword, index) in displayedKeywords"
          :key="'displayedKeywords' + index"
        >
          <div
            class="facet-filter show-all"
            v-if="!['mentionsMin', 'mentionsMax'].includes(getFacetLabelForKeyword(keyword[0]))"
          >
            <div class="facet-filter__title">
              {{ capitalize(getFacetLabelForKeyword(keyword[0])) }}
            </div>
            <div class="facet-filter__facet">
              <div style="display: inline-block">
                <ul class="category-list">
                  <li
                    class="category" v-for="(facetItem, index) in keyword"
                    :key="'facet' + index"
                  >
                    <facet-filter-button
                      :disabled="isLoading"
                      :facetLabel="getGeoOrLabelForKeyword(facetItem, facetItem.label || String(facetItem.value))"
                      :allow-toggle="false"
                      @click="removeKeyword(facetItem)"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
        <div class="facet-filter__more-section">
          <span
            @click="toggleMoreFacetCounts"
            v-if="hasEnoughFacetCountsToShowMoreButton"
            class="facet-filter__link"
          >
            <template v-if="showMoreFacetCounts">
              {{ $t('actors_simplified_show_less_filters') }}<icon
              name="chevron-up"
            ></icon>
            </template>
            <template v-else>
              {{ $t('actors_simplified_show_all_filters') }}<icon
              name="chevron-down"
            ></icon>
            </template>
          </span>
          <span
            @click="showAllFilters"
            v-if="allowAllFilters && (showMoreFacetCounts || !hasEnoughFacetCountsToShowMoreButton)"
            class="facet-filter__link" style="margin-left: auto"
          >
            + {{ $t('actors_simplified_show_all_filters') }}
          </span>
        </div>
      </div>
      <div
        class="home-simplified__global-search-link home-simplified__space-vertical"
        v-if="isMobile"
      >
        <a href="#" @click="showAllFilters">&#8594;
          {{ $t('add_filters') }}</a>
      </div>
      <ul class="category-list">
        <li class="category" :class="{ 'selected': selectedView === 'list'}">
          <button @click="selectedView = 'list'" class="icon-button">
            <icon name="view-gallery" size="14"/>
            {{ $t('simplified_actors_actor_view') }}
          </button>
        </li>
        <li class="category" :class="{ 'selected': selectedView === 'map'}">
          <button @click="selectedView = 'map'" class="icon-button">
            <icon name="view-map" size="14"/>
            {{ $t('simplified_actors_map_view') }}
          </button>
        </li>
        <li class="category selected" v-if="isProductsPage === false && isLoggedIn && canAddActors">
          <button @click="createActor" class="icon-button">
            <icon name="plus" size="14"/>
            {{ $t('add_actor_title') }}
          </button>
        </li>
        <li
          class="category selected"
          v-if="isProductsPage === false && isLoggedIn && config.ecosystem_owner_contact !== userId && config.ecosystem_owner_contact && isEcosystemMember && !this.communityId"
        >
          <button
            class="icon-button"
            :class="{'start-conversation': spottingArea.highlight}"
            :style="{backgroundColor: spottingArea.highlight ? highlightedSpottingArea.highlight_color : ''}"
            @click="startConversation(config.ecosystem_owner_contact)"
          >
            <icon name="plus" size="14"/>
            {{ $t('become_an_actor_title') }}
          </button>
        </li>
        <li class="category selected" v-if="isProductsPage === true && isLoggedIn && canMakeProduct">
          <button
            @click="nonProductClaims.length === 0 ? showRestrictedModal() : showAddProductModal()"
          >
            <icon name="plus" size="14"/>
            {{ $t('add_new_product', { product: this.productLabel }) }}
          </button>
        </li>
      </ul>
      <br>
      <template v-if="isLoading">
        <Loading/>
      </template>
      <template v-else>
        <template v-if="!hasKeywords && selectedView === 'list'">
          <template v-for="(_, index) in noKeywordsGroupCount">
            <div class="row" style="margin-bottom: 0" v-if="actors.data">
              <div
                class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 home-simplified__space-vertical"
                v-for="actor in actors.data.slice(index * 3, (index + 1) * 3)"
              >
                <simplified-image-card
                  :name="actor.name"
                  :full-size="true"
                  :image="actor.featured_image_url || actor.website_screenshot"
                  :show-highlighted-spotting-area="actor.is_inside_highlighted_spotting_area"
                  :sub-title="localizedDisplayProperty(actor, 'short_description') || localizedDisplayProperty(actor, 'activities_description')"
                  :link="'/actors/' + actor.id"
                  :left-align-text="true"
                  :class="cardClass"
                  style="background-color: var(--primary-community-extra-lightest); height: 100%"
                  @click="handleClickActorCard(actor)"
                />
              </div>
            </div>
          </template>
        </template>
        <template v-if="hasKeywords && selectedView === 'list'">
          <div class="row">
            <div class="col-xs-12" v-if="actors.data.length === 0">
              <div>
                {{ $t('actors_none_for_current_filters_1') }}
                <label-filter
                  @updateFilter="handleClickClearAllKeywords"
                  :text="$t('categories_all')"
                ></label-filter>
                {{ $t('actors_none_for_current_filters_2') }}
              </div>
            </div>
            <div
              v-if="actors.data"
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 home-simplified__space-vertical"
              v-for="actor in actors.data.slice(0, actorLimitWithActiveKeywords)"
            >
              <simplified-image-card
                :name="actor.name"
                style="background-color: var(--primary-community-extra-lightest); height: 100%"
                :full-size="true"
                :left-align-text="true"
                :image="actor.featured_image_url || actor.website_screenshot"
                :link="'/actors/' + actor.id"
                @click="handleClickActorCard(actor)"
                :sub-title="localizedDisplayProperty(actor, 'short_description') || localizedDisplayProperty(actor, 'activities_description')"
              />
            </div>
          </div>
        </template>
        <template v-if="selectedView === 'map'">
          <br>
          <div style="min-height: 400px; height: calc(100vh - 300px); position: relative;">
            <map-view
              style="height: calc(100% - 3rem)"
              ref="mapPane"
              :data="mapData.data"
              :getMapData="getMapData"
              :getData="getMapData"
              :noSearchWhileMoving="true"
              :disableMouseWheelZoom="true"
            />
          </div>
        </template>
      </template>
    </Container>


    <SimplifiedFooter
      v-if="$route.name !== 'community-detail' && $route.name !== 'spotting-area-detail'"
    />
  </div>
</template>

<script>
  import Container from '../../components/Container/Container.vue'
  import SimplifiedFooter from '../../components/SimplifiedFooter/SimplifiedFooter.vue'
  import BasicSimplifiedHero from '../../components/Simplified/BasicSimplifiedHero.vue'
  import MapView from '../../components/MapView/MapView.vue'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors.js'
  import FiltersMixin from '../../util/FiltersMixin.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { DEFAULT_LIMIT, MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { fetchStatsData } from '../../api/homepage.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { getTranslationsForTaxonomyValue } from '../../store/modules/taxonomies.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import ConfigMixin from '../../util/ConfigMixin.js'
  import { getKeywordsFromState, getReportFieldByReportIdentifier } from '../../util/helpers.js'
  import { handleRemoveNestedKeyword, handleRemoveReportKeyword } from '../../util/helpers-filters.js'
  import KeywordWithFacet from '../../components/Keyword/KeywordWithFacet.vue'

  import HoverOverlayButton from '../../components/Overlay/HoverOverlayButton.vue'
  import { facetCount } from '../../api/actors.js'
  import _debounce from 'lodash/debounce.js'
  import LabelFilter from '../../components/Filters/LabelFilter.vue'
  import SimplifiedImageCard from '../../components/Connection/SimplifiedImageCard.vue'
  import UiMixin from '../../util/UiMixin.js'
  import { sustainabilityGoals, sustainabilityGoalsNL } from '../../constants/config.js'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import FacetFilterButton from '../../components/FacetButton/FacetFilterButton.vue'
  import KeywordLabelMixin from '../../util/KeywordLabelMixin.js'
  import _groupBy from 'lodash/groupBy.js'
  import { capitalize } from '../../util/string.ts'
  import Loading from '../../components/Dashboard/ConceptMap/Loading.vue'
  import { defineComponent } from 'vue'
  import { Conversations, createComment } from '@/api/conversations.js'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '@/store/modules/conversations.js'
  import ReportMixin from '@/util/ReportMixin'
  import CommunityMixin from '@/util/CommunityMixin'

  export default defineComponent({
    name: 'ActorsSimplified',
    components: {
      FacetFilterButton,
      LabelFilter,
      HoverOverlayButton,
      KeywordWithFacet,
      Container,
      MapView,
      SimplifiedFooter,
      BasicSimplifiedHero,
      SimplifiedImageCard,
      Loading,
    },
    props: {
      enableBanner: {
        type: Boolean,
        default: true,
      },
      cardClass: {
        type: String,
        default: '',
      },
      canAddActors: {
        type: Boolean,
        default: true,
      },
    },
    data: () => {
      return {
        noKeywordsGroupCount: 3,
        actorLimitWithActiveKeywords: 9,
        legendCounts: {},
        facetCounts: [],
        componentKey: 1,
        showAll: {},
        windowWidth: window.innerWidth,
        selectedView: 'list',
        showMoreFacetCounts: false,
        owners: [],
      }
    },
    computed: {
      isEcosystemMember () {
        return this.$store.getters.isActor
      },
      userId () {
        return this.$store.getters.userId
      },
      highlightedSpottingArea () {
        return this.$store.state.spottingAreas.highlightedSpottingArea.data
      },
      spottingArea () {
        return this.$store.state.spottingAreas.detail.data
      },
      canMakeProduct () {
        return this.$store.state.config.createProductPolicy === 'many'
      },
      isLoading () {
        return false
        // return this.$store.state.actors.listData.loading || this.$store.state.actors.productData.loading
      },
      visibleFacets () {
        return this.facetCounts.filter(facetCount => this.canShowFacet(facetCount.count, facetCount.name))
      },
      limitedFacetCounts () {
        if (this.showMoreFacetCounts) {
          return this.visibleFacets
        }

        return this.visibleFacets.slice(0, this.maxNumberOfNonSdgFilters)
      },
      hasEnoughFacetCountsToShowMoreButton () {
        return this.visibleFacets.length > this.maxNumberOfNonSdgFilters
      },
      maxNumberOfNonSdgFilters () {
        return this.sdgFacetCount.length > 0 ? 2 : 3
      },
      originalProfile () {
        return this.$store.state.user.profile
      },
      nonProductClaims () {
        if (!this.originalProfile || !this.originalProfile.claims) {
          return []
        }
        return this.originalProfile.claims.filter(claim => claim.actor_type !== 'Product')
      },
      showAddActorButton () {
        return !this.isProductsPage && this.userCanAddActor
      },
      translatedSustainabilityGoals () {
        return this.$store.getters.activeLocale === 'nl' ? sustainabilityGoalsNL : sustainabilityGoals
      },
      sdgImagesPath () {
        return this.$store.getters.activeLocale === 'nl' ? '/images/SDG_nl/' : '/images/SDG/'
      },
      allowAllFilters () {
        if (this.isProductsPage) {
          return this.config.allowAllFiltersProducts
        }

        return this.config.allowAllFilters
      },
      availableFilterControls () {
        if (this.isMember) {
          return this.visibleFilters
        }

        // If the user is a portfolio member, take the setted filter controls directly from the permissions tab. Since it is no where else accessible
        if (this.isPortfolioMember) {
          return this.config.permissions.portfolio_member.filterControls
        }

        if (this.isProductsPage) {
          return this.config.simplifiedFilterControlsProducts || []
        }

        return this.config.simplifiedFilterControls || []
      },
      createProductLabel () {
        return this.$t('plus_button_create_product', { product: this.productLabel })
      },
      mapData () {
        return this.$store.state.actors.mapData
      },
      isMobile () {
        return this.ui.isMobile
      },
      sdgFacetCount () {
        const result = this.facetCounts.find(facetCount => facetCount.name === 'sustainability_goal')
        if (!result || !result.count || !result.count.length) {
          return []
        }
        return result.count
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember
      },
      userCanAddActor () {
        return this.$store.getters.userCanCreate && !this.canOnlyCreateProducts
      },
      canUserAddProduct () {
        return this.$store.getters.userCanCreate && this.$store.getters.claimableActorTypes && this.$store.getters.claimableActorTypes.includes('Product')
      },
      canOnlyCreateProducts () {
        return this.$store.getters.claimableActorTypes.includes('Product') && this.$store.getters.claimableActorTypes.length === 1
      },
      heapPrefix () {
        return this.isProductsPage ? 'productsSimplified.' : 'actorsSimplified.'
      },
      totalActorsCount () {
        return this.actors.count
      },
      hasKeywords () {
        return this.$store.state.filters.keywords.length > 0
      },
      filters () {
        return this.$store.getters.baseFilterObject
      },
      actors () {
        if (this.isProductsPage) {
          return this.$store.state.actors.productData
        }
        return this.$store.state.actors.listData
      },
      config () {
        return this.$store.state.config
      },
      locale () {
        return this.$store.getters.activeLocale
      },
      keywords () {
        return this.$store.state.filters.keywords
      },
      displayedKeywords () {
        let displayedKeywords = []
        let simpleKeywords = this.keywords || []

        simpleKeywords = simpleKeywords.filter((keyword) => {
          return keyword.value !== '-'
            && keyword.facet !== 'actor_type'
            && keyword.facet !== 'legendProperty'
            && !keyword.facet.includes('report_field_')
            && !this.availableFilterControls.includes(keyword.facet)
            && keyword.facet !== 'sustainability_goal' && keyword.facet !== 'portfolio_ids'
        })

        displayedKeywords = displayedKeywords.concat(simpleKeywords)
        getKeywordsFromState(displayedKeywords, this.$store, [])//this.filterableReportFields) // report fields are an integral part of the facets
        return _groupBy(displayedKeywords, 'facet')
      },
      legendItems () {
        if (!this.legendCounts.stats) {
          return []
        }

        return this.legendCounts.stats.map(result => {
          const translations = getTranslationsForTaxonomyValue(this.legendCounts.property, result.value)
          let label

          if (translations && translations[this.locale] && translations[this.locale].name) {
            label = translations[this.locale].name
          } else {
            label = translations['en'].name
          }

          result['label'] = label

          return result
        })
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      title () {
        return this.isLoggedIn
          ? this.localizedDisplayProperty(this.config, 'introTitleLoggedIn')
          : this.localizedDisplayProperty(this.config, 'introTitleLoggedOut')
      },
      pageOffset () {
        return this.$store.state.filters.paging.offset || 0
      },
      pageLimit () {
        return this.$store.state.filters.paging.limit || DEFAULT_LIMIT
      },
    },
    mixins: [FiltersMixin, TranslationsMixin, ConfigMixin, UiMixin, OnboardingMixin, KeywordLabelMixin, ReportMixin, CommunityMixin],
    methods: {
      capitalize,
      startConversation (contributor) {
        this.$emit('track-event')

        Conversations
          .post({ recipient_id: contributor })
          .then((conversation) => {
            this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
            this.$router.push('/profile/conversations/' + conversation.id)
            let automaticComment = `Hi! I would like to add my actor(s) to the spotting area of " ${this.spottingArea.name} ". Could you help me with this?`
            createComment(conversation.id, automaticComment)
              .then(() => {
                if (this.$refs.contenteditable) {
                  this.$refs.contenteditable.innerText = ''
                }
                this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
              })
              .catch(errors => {
                this.errors = errors
              })
          })
      },
      toggleMoreFacetCounts () {
        this.showMoreFacetCounts = !this.showMoreFacetCounts
        // Timeout to wait for the facets' "show more" height to render
        setTimeout(() => {
          this.componentKey++
        })
      },
      getIndexForSdgLabel (sdgLabel) {
        return sdgLabel.split(' ')[1].split(':')[0]
      },
      showRestrictedModal () {
        trackHeapEvent('restrictedModal')
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.RESTRICTED_MODAL)
      },
      onScrollToEndOfPage () {
        if (this.hasKeywords) {
          this.actorLimitWithActiveKeywords = this.actorLimitWithActiveKeywords + 9
          if (this.actorLimitWithActiveKeywords >= (this.pageOffset + this.pageLimit) && this.actors.data.length < this.totalActorsCount) {
            this.$store.commit(FILTERS_MUTATION_TYPES.SET_OFFSET, this.pageOffset + this.pageLimit)
            this.fetchMoreFilteredActors()
          }
        } else {
          this.noKeywordsGroupCount = this.noKeywordsGroupCount + 3
          if (this.noKeywordsGroupCount >= (this.pageOffset + this.pageLimit) / 3 && this.actors.data.length < this.totalActorsCount) {
            this.$store.commit(FILTERS_MUTATION_TYPES.SET_OFFSET, this.pageOffset + this.pageLimit)
            this.fetchMoreFilteredActors()
          }
        }
      },
      onScroll: _debounce(function (e) {
        if ((e.target.scrollTop + e.target.offsetHeight + 1000) > e.target.scrollHeight) {
          this.onScrollToEndOfPage()
        }
      }, 200),
      createActor () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
      },
      getMapData () {
        const filters = {
          ...this.$store.getters.listFilterObject,
          ...this.$store.getters.mapFilterObject,
        }

        if (this.isProductsPage) {
          filters.actor_type = 'Product'
        }

        if (filters.tl) {
          this.$store.dispatch(this.isProductsPage ? ACTORS_ACTION_TYPES.FETCH_PRODUCTS_MAP : ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
        }
      },
      canShowFacet (facet, facetName) {
        const hasContent = facet && facet.length > 0

        if (!hasContent || facetName === 'sustainability_goal') {
          return false
        }
        if (facetName === 'portfolio_ids') {
          return true
        }

        if (facetName.startsWith('report_field_') && ['options', 'multi_select', 'dropdown'].includes(this.allReportFieldsMap[facetName]?.type)) {
          return this.areReportFiltersEnabled
        }

        return this.availableFilterControls.includes(facetName)
      },
      areReportFiltersEnabled () {
        return this.availableFilterControls.includes('reports')
      },
      getFacetTitleLabel (facet) {
        if (facet.name === 'portfolio_ids') {
          return 'Portfolio'
        }

        if (facet.parent_name) {
          return this.getLabelForTaxonomyValue(facet.name, facet.parent_id)
        }

        if (facet.name.startsWith('report_field_')) {
          const reportField = getReportFieldByReportIdentifier(facet.name)

          if (reportField) {
            return reportField.label
          }

          return ''
        }

        return this.getTaxonomyAliasWithDefault(facet.name)
      },
      handleResize () {
        this.windowWidth = window.innerWidth
      },
      hasExceededLengthLimit (facetName, customHeight, parentId) {
        const maxHeight = customHeight || 52

        if (facetName === 'sustainability_goal') {
          if (this.$refs['facet' + facetName]) {
            return this.$refs['facet' + facetName].offsetHeight > maxHeight
          }
        }

        var refName = 'facet' + facetName

        if (parentId) {
          refName += parentId
        }

        if (this.$refs[refName] && this.$refs[refName][0]) {
          return this.$refs[refName][0].offsetHeight > maxHeight
        }
      },
      toggleShowAll (facetName) {
        this.showAll[facetName] = !this.showAll[facetName]
        this.$forceUpdate()
      },
      fetchFacetCounts () {
        const originalListFilters = {
          ...this.$store.getters.listFilterObject,
        }

        const listFilters = this.isProductsPage ? Object.assign({}, originalListFilters, { actor_type: 'Product' }) : Object.assign({}, originalListFilters, { actor_type: ['LegalEntity', 'Person'] })

        facetCount(this.isProductsPage ? ['Product'] : ['LegalEntity', 'Person'], listFilters)
          .then((result) => {
            // Remove portfolio_ids from the filters when on a specific page
            if (this.$route.name === 'community-detail' || this.$route.name === 'spotting-area-detail') {
              delete result['portfolio_ids']
            }

            this.facetCounts = this.orderBySimplifiedFilterControls(result, this.availableFilterControls)

            setTimeout(() => {
              this.componentKey++
            })
          })
      },
      addActor () {
        const productModalContext = {
          prefilled: {
            actor_type: 'Product',
          },
          afterCreate: 'goToActor',
        }
        const actorModalContext = {
          afterCreate: 'goToActor',
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, this.isProductsPage ? productModalContext : actorModalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
      },
      updateLegendProperty () {
        if (this.isProductsPage) {
          const productLegendProperty = this.$store.getters.productLegendProperty

          this.$store.commit(FILTERS_MUTATION_TYPES.SET_LEGEND_PROPERTY, {
            property: productLegendProperty,
            items: this.getAvailableLegendItems(productLegendProperty),
            noCache: true,
          })
        } else {
          const legendProperty = this.$store.getters.defaultLegendProperty

          this.$store.commit(FILTERS_MUTATION_TYPES.SET_LEGEND_PROPERTY, {
            property: legendProperty,
            items: this.getAvailableLegendItems(legendProperty),
          })
        }

        // Making sure that the spotting area is set when fetching the actors
        if (this.$route.name === 'spotting-area-detail' && (!this.$store.state.filters.spottingArea || (this.$store.state.filters.spottingArea !== this.$route.params.spottingAreaId))) {
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_SPOTTING_AREA, this.$route.params.spottingAreaId)
        }
      },
      showAllFilters () {
        trackHeapEvent(this.heapPrefix + 'showAllFilters')
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ALL_FILTERS)
      },
      handleClickActorCard (actor) {
        trackHeapEvent(this.heapPrefix + 'clickCard')

        if (this.$route.name === 'community-detail') {
          this.$router.push(`/communities/${this.$route.params.communityId}/actors/${actor.id}`)
        } else if (this.$route.name === 'spotting-area-detail' && !this.$store.getters.isMember) {
          this.$router.push(`/spotting-areas/${this.$route.params.spottingAreaId}/actors/${actor.id}`)
        } else {
          this.$router.push(`/actors/${actor.id}`)
        }
      },
      removeKeyword (keyword) {
        trackHeapEvent(this.heapPrefix + 'removeFilterFacet')
        handleRemoveNestedKeyword(this.$store, keyword)
        handleRemoveReportKeyword(this.$store, keyword)

        this.$store.commit(FILTERS_MUTATION_TYPES.REMOVE_KEYWORD, keyword)
      },
      handleSelectFacet (facetName, facetValue) {
        if (this.isLoading) {
          return
        }

        if (facetName === 'portfolio_ids') {
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO_NO_RESET, facetValue)
        } else if (facetName.startsWith('report_field_')) {
          var context = { reportId: facetName, value: facetValue }

          this.$store.commit(FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, context)
        } else {
          this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM, {
            facet: facetName,
            value: facetValue,
          })
        }

        trackHeapEvent(this.heapPrefix + 'clickLegendItem', { facetValue })
      },
      handleClickClearAllKeywords (facetName, parentId = null) {
        if (facetName) {
          trackHeapEvent(this.heapPrefix + 'clearAllKeywords', { facet: facetName })
        } else {
          trackHeapEvent(this.heapPrefix + 'clearAllKeywords')
          this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
        }

        if (facetName.startsWith('report_field_')) {
          this.$store.commit(FILTERS_MUTATION_TYPES.RESET_REPORT_FIELD, { reportId: facetName })

          return
        }

        // If product features a,b,c are cleared, don't remove all values, but only the ones belonging to the row that was clicked
        // PF a,b,c are deduplicated, where every sub feature is a separate row to filter on
        if (['product_features_a', 'product_features_b', 'product_features_c'].includes(facetName)) {
          // Get the ids that we need to filter out
          var facetsToClear = []

          this.facetCounts.forEach((facet) => {
            if (facet.count.filter(facet => facet.parent_id == parentId).length > 0) {
              facetsToClear = facet.count.map(facet => facet.value)
            }
          })

          var remainingFacets = this
            .keywords
            .filter(keyword => keyword.facet == facetName)
            .filter(keyword => !facetsToClear.includes(keyword.value))
            .map(keyword => keyword.value)

          if (facetName == 'product_features_a') {
            this.$store.commit(FILTERS_MUTATION_TYPES.SET_PRODUCT_FEATURES_A, remainingFacets)
          } else if (facetName == 'product_features_b') {
            this.$store.commit(FILTERS_MUTATION_TYPES.SET_PRODUCT_FEATURES_B, remainingFacets)
          } else if (facetName == 'product_features_c') {
            this.$store.commit(FILTERS_MUTATION_TYPES.SET_PRODUCT_FEATURES_C, remainingFacets)
          }

          return
        }

        if (facetName === 'portfolio_ids') {
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO_NO_RESET, '-')
        }

        for (const keyword of this.keywords) {
          if (keyword.facet === facetName) {
            handleRemoveNestedKeyword(this.$store, keyword.facet)
            handleRemoveReportKeyword(this.$store, keyword.facet)

            this.$store.commit(FILTERS_MUTATION_TYPES.REMOVE_KEYWORD, keyword)
          }
        }
      },
      fetchMoreFilteredActors () {
        const listFilters = {
          ...this.$store.getters.listFilterObject,
        }

        if (this.isProductsPage) {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_MORE_PRODUCTS_LIST, Object.assign({}, listFilters, { actor_type: 'Product' }))
        } else {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_MORE_ACTORS_LIST, Object.assign({}, listFilters, { actor_type: ['LegalEntity', 'Person'] }))
        }
      },
      fetchFilteredActors (fetchBoth) {
        const listFilters = {
          ...this.$store.getters.listFilterObject,
        }

        if (this.isProductsPage || fetchBoth) {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_PRODUCTS_LIST, Object.assign({}, listFilters, {
            actor_type: 'Product',
            useCombinedTaxonomyFilters: true
          }))
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_PRODUCTS_COUNT, Object.assign({}, listFilters, {
            actor_type: 'Product',
            useCombinedTaxonomyFilters: true
          }))
        }

        if (!this.isProductsPage || fetchBoth) {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, Object.assign({}, listFilters, {
            actor_type: ['LegalEntity', 'Person'],
            useCombinedTaxonomyFilters: true
          }))
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_COUNT, Object.assign({}, listFilters, {
            actor_type: ['LegalEntity', 'Person'],
            useCombinedTaxonomyFilters: true
          }))
        }
        this.getMapData()
      },
      getCategoryButtonStyles (facetName, value, parentId) {
        const activeKeywords = this.$store.state.filters.keywords
        const activeCategoryButtonStyles = {
          backgroundColor: '#CECECE',
          color: 'black',
        }
        const inactiveCategoryButtonStyles = {
          backgroundColor: '#fff',
          color: 'black',
        }

        if (facetName.startsWith('report_field') && this.$store.state.filters.reportFilters[facetName]) {
          const facetFilters = this.$store.state.filters.reportFilters[facetName]
          const isActive = Array.isArray(facetFilters) ? facetFilters.length > 0 : !!facetFilters

          return !isActive
            ? activeCategoryButtonStyles
            : inactiveCategoryButtonStyles
        }

        // Product features are treated differently, every sub feature is a separate filtering row
        if (['product_features_a', 'product_features_b', 'product_features_c'].includes(facetName)) {
          // !value identifies the "all" option
          if (!value) {
            // Get the ids that we need to filter out
            var productFeatureRowOptions = []

            this.facetCounts.forEach((facet) => {
              if (facet.count.filter(facet => facet.parent_id == parentId).length > 0) {
                productFeatureRowOptions = facet.count.map(facet => facet.value)
              }
            })

            const isActive = Boolean(
              activeKeywords.find(
                k => k.facet === facetName && productFeatureRowOptions.includes(k.value),
              ),
            )

            return !isActive
              ? activeCategoryButtonStyles
              : inactiveCategoryButtonStyles
          }

          const isActive = Boolean(
            activeKeywords.find(
              k => k.facet === facetName && k.value === value,
            ),
          )

          return isActive
            ? activeCategoryButtonStyles
            : inactiveCategoryButtonStyles
        }

        if (!value) {
          if (facetName === 'portfolio_ids') {
            const containsNoFilters = !this.$store.state.filters.portfolio
            return containsNoFilters
              ? activeCategoryButtonStyles
              : inactiveCategoryButtonStyles
          } else {
            const containsNoFilters = !activeKeywords.find(k => k.facet === facetName)
            return containsNoFilters
              ? activeCategoryButtonStyles
              : inactiveCategoryButtonStyles
          }
        } else if (facetName === 'portfolio_ids') {
          const isActive = this.$store.state.filters.portfolio === value
          return isActive
            ? activeCategoryButtonStyles
            : inactiveCategoryButtonStyles
        } else {
          const isActive = Boolean(
            activeKeywords.find(
              k => k.facet === facetName && k.value === value,
            ),
          )
          return isActive
            ? activeCategoryButtonStyles
            : inactiveCategoryButtonStyles
        }
      },
      isSdgSelected (value) {
        return this.$store.state.filters.keywords.find(
          k => k.facet === 'sustainability_goal' && k.value === value,
        )
      },
      fetchStatsData () {
        const parameters = {}

        parameters.legendProperty = this.$store.state.filters.legendProperty // Don't use the legendProperty getter because it's provided by the config.legendProperty getter not the filters one

        fetchStatsData(parameters)
          .then(response => {
            this.legendCounts = response.data
          })
          .catch(errors => {
            //
          })
      },
      showAddProductModal () {
        trackHeapEvent('addProduct')
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_PRODUCT)
      },
    },
    mounted () {
      this.updateLegendProperty()
      this.fetchStatsData()
      this.fetchFilteredActors()
      this.fetchFacetCounts()

      this.$bus.on('actorCreated', () => this.fetchFilteredActors(true))
      this.$bus.on('actorUpdated', () => this.fetchFilteredActors(true))
      this.$bus.on('scrollToEndOfSpottingAreaPage', () => this.onScrollToEndOfPage())
      this.$bus.on('resize300', () => {
        this.handleResize()
      })

      if (this.$route.query.openModal && this.isLoggedIn) {
        this.createActor()
        this.$router.replace({ 'query': null })
      }
    },
    beforeUnmount () {
      this.$bus.off('actorCreated')
      this.$bus.off('actorUpdated')
      this.$bus.off('scrollToEndOfSpottingAreaPage')

      // Swap back to the default legend property
      var legendProperty = this.$store.getters.defaultLegendProperty

      this.$store.commit(FILTERS_MUTATION_TYPES.SET_LEGEND_PROPERTY, {
        property: legendProperty,
        items: this.getLegendItems(legendProperty),
      })
      this.$bus.off('resize300')
    },
    watch: {
      filters: {
        deep: true,
        handler () {
          this.fetchFilteredActors()
          this.fetchFacetCounts()
        },
      },
      'isProductsPage': {
        handler () {
          this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
          this.selectedView = 'list'
          this.updateLegendProperty()
          this.fetchStatsData()
          this.fetchFilteredActors()
          this.fetchFacetCounts()
        },
      },
    },
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .actor-simplified {
    &.home-simplified {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;

      .headline {
        margin: 0 auto;
        max-width: 600px;

        input {
          background-color: white;
        }
      }

      .leaflet-container {
        margin-top: 0;
      }
    }

    .container {
      @media (max-width: $screen-md) {
        width: 100%;
      }
    }

    .facet-filters {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
    }

    .facet-filter {
      display: flex;
      height: 29px;
      overflow: hidden;

      .facet-filter__facet {
        display: flex;
        height: 30px;
        width: calc(100% - 270px);
      }

      &.facet-filter-sdg {
        height: 30px;

        .facet-filter__title {
          line-height: 30px;
        }
      }

      &.show-all {
        height: auto;
        overflow-y: visible;

        .facet-filter__facet {
          height: auto;
        }

        .facet-filter-sdg {
          height: 30px;
        }
      }

      .facet-filter__title {
        overflow: hidden;
        width: 200px;
        min-width: 200px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 30px;
      }
    }

    .facet-filter__more-section {
      display: flex;
      justify-content: space-between;
    }

    .facet-filter__link {
      color: var(--primary-community);
      text-decoration: underline;
      cursor: pointer;

      .svg-icon {
        margin-bottom: -5px;

        svg path {
          fill: var(--primary-community);
        }
      }
    }

    .facet-filter__show-more-container {
      width: 70px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 30px;
      margin-left: auto;
      padding-left: 5px;
      margin-top: auto;
    }

    .facet-filter__show-more-link {
      width: 100%;
      line-height: 30px;
      font-size: 12px;
      text-align: right;
      color: black;
    }

    .home-simplified__global-search-link {
      display: flex;
      justify-content: flex-end;
    }

    .simple-hero {
      margin-bottom: 1rem;
      height: 400px;
      background-size: cover;
    }

    .simple-hero__container {
      position: relative;
      height: 100%;
      padding-top: 1rem;

      ul, ol {
        margin-left: 1rem;
      }
    }

    .simple-hero__title {
      font-size: 1.5rem;
      line-height: calc(2rem + 8px);
      text-align: center;
    }

    .simple-hero__content {
      margin-bottom: 1rem;
      padding: 1rem;
      color: #fff;
      text-shadow: 0 0 4px black;
    }

    .simple-hero__description {
      text-align: center;

      * {
        font-size: 0.875rem;
      }
    }

    .category-list {
      list-style-type: none;
    }

    .category {
      display: inline-block;
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
      background-color: white;

      &:last-child {
        margin-right: 0;
      }

      button {
        padding: 5px;
        border-radius: $default-border-radius-narrow;
        border: 1px solid var(--primary-community-extra-lightest);
        font-family: $font-stack-primary;
        font-size: 11px;
        opacity: 1;
        transition: all 0.2s;
        background-color: white;
        color: black;
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &.icon-button {
          font-size: 12px;
        }

        &.start-conversation {
          color: white !important;

          svg path {
            fill: white !important;
          }
        }

        svg path {
          fill: black;
        }

        &:focus {
          outline: none;
        }

      }

      &:focus button {
        outline: none;
      }

      &.selected button {
        background: var(--primary-community-extra-lightest);
        color: black;
        border: 1px solid var(--primary-community-extra-lightest);
        font-weight: 500;

        svg path {
          fill: black;
        }
      }
    }

    .category--active {
      button {
        font-weight: bold;
        opacity: 1;
        border: 1px solid var(--primary-community-extra-lightest);
      }
    }

    .home-simplified__space-vertical {
      margin-bottom: 30px;
    }

    .category-list {
      font-size: 0;

      .simplified-facet-container {
        &.keyword {
          background-color: var(--primary-community);
          border: 1px solid black;
          margin-bottom: 4px;
          line-height: 29px;
          font-weight: 400;
          font-size: 16px;

          .svg-icon--remove {
            svg {
              top: 4px;

              path {
                stroke: white;
              }
            }
          }

          .keyword__label {
            display: flex;
            flex-direction: row-reverse;
            color: white;

            div:last-child:after {
              content: " - ";
              margin-right: 3px;
            }

            .keyword__label__facet {
              font-size: 16px;
            }
          }
        }
      }
    }

    h2.h2 {
      font-size: 16px;
    }
  }
</style>
