<template>
  <!-- TODO: Refactor this component to use the Card component or build some sort of a community detail card  -->
  <div class="row">
    <div v-if="!displayCommunityAnnouncements" class="col-xs-12 col-sm-12 col-md-6">
      <div class="communities-title">
        {{
          $t('community_recent_announcements', { announcements: announcementsLabel })
        }}
      </div>

      <div class="community-detail__announcements">
        <div class="communities-detail__empty">
          {{ requestAccessToCommunitiesViaAnnouncementText }}
        </div>

        <DsButton
            v-if="displayRequestAccessButton"
            style="margin-top: 1rem;"
            :label="requestAccessButtonLabel"
            :disabled="hasUserRequestedAccessToCommunity"
            @click="requestAccessToTheCommunity"
        />
      </div>
    </div>

    <div v-else-if="displayCommunityAnnouncements" class="col-xs-12 col-sm-12 col-md-6">
      <div class="communities-title">
        <RouterLink
            style="text-decoration: none"
            v-tooltip.top="$t('tooltip_announcements_simplified')"
            :to="`/communities/${community.id}/announcements`"
        >
          {{
            $t('community_recent_announcements')
          }}
        </RouterLink>
      </div>

      <div
          class="community-detail__announcements"
          v-if="loadingAnnouncements"
      >
        <div class="communities-detail__empty">
          <loading/>
        </div>
      </div>

      <div v-else-if="recentAnnouncements.length">
        <div>
          <simplified-announcement-preview
              style="margin-bottom: 0"
              :is-vertical="true"
              :is-simplified="true"
              :is-container="false"
              :is-community-detail="true"
              :community="community"
              :announcement="recentAnnouncements[0]"
          />
        </div>
      </div>

      <!-- Announcements show card when empty -->
      <div v-else class="community-detail__announcements">
        <div class="communities-detail__empty">
          {{
            $t('community_announcements_empty_text', { announcements: announcementsLabel })
          }}
        </div>
      </div>
    </div>


    <!-- Upcoming events show card for visitors -->
    <div v-if="!displayCommunityAnnouncements" class="col-xs-12 col-sm-12 col-md-6">
      <div class="communities-title">
        {{
          $t('community_upcoming_events')
        }}
      </div>
      <div class="community-detail__announcements">
        <div class="communities-detail__empty">
          {{ requestAccessToCommunitiesViaEventText }}
        </div>

        <DsButton
            v-if="displayRequestAccessButton"
            style="margin-top: 1rem;"
            :label="requestAccessButtonLabel"
            :disabled="hasUserRequestedAccessToCommunity"
            @click="requestAccessToTheCommunity"
        />
      </div>
    </div>

    <!-- Upcoming events show card for members -->
    <div v-else-if="displayCommunityAnnouncements" class="col-xs-12 col-sm-12 col-md-6">
      <div class="communities-title">
        <router-link
            :to="`/communities/${community.id}/events`"
            style="text-decoration: none"
        >
          {{
            $t('community_upcoming_events')
          }}
        </router-link>
      </div>
      <div class="community-detail__events" v-if="loadingEvents">
        <div style="margin: auto">
          <loading/>
        </div>
      </div>
      <div v-else-if="upcomingEvents.length > 0" class="row community-detail__upcoming-events-container">
        <div
            :class="{'col-xs-12 col-sm-12': true, 'col-md-6': upcomingEvents.length > 1}"
            v-for="(event, index) in upcomingEvents"
            :key="'upcoming-events' + index"
        >
          <div class="community-detail__upcoming-event">
            <event-card
                is-community-detail
                :community="community"
                show-image
                :event="event"
                class="event-simplified-image-card has-background-white"
                :link="`/communities/${community.id}/announcements/${event.id}`"
            />
          </div>
        </div>
      </div>
      <div class="community-detail__events" v-else>
        <div class="communities-detail__empty">
          {{ $t('community_events_empty_text') }}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <template v-if="displayCommunityChallenges && limitedChallenges.length > 0 && doesUserHaveFullAccessToCommunity">
      <div class="communities-title">
        <router-link
            :to="`/communities/${community.id}/events`"
            style="text-decoration: none"
        >
          {{ $t('shp_latest_challenge', { challenge: challengesLabel }) }}
        </router-link>
      </div>
      <div class="community-detail__challenges" v-if="isLoadingChallenges">
        <div style="margin: auto">
          <loading/>
        </div>
      </div>

      <div
          v-if="limitedChallenges.length > 0"
          style="width: 100%;"
      >
        <challenge-card
            v-for="(challenge, index) in limitedChallenges"
            :key="'challenge' + index"
            :challenge="challenge"
            @set-filter="null"
            :can-toggle-status-filter="false"
            :can-toggle-visibility-filter="false"
            @toggle-pin-status="null"
            :is-accessible-for-user="isChallengeAccessibleForUser"
        >
        </challenge-card>
      </div>
      <div class="community-detail__events" v-else>
        <div class="communities-detail__empty">
          {{ $t('community_events_empty_text') }}
        </div>
      </div>
    </template>

    <template v-else-if="areChallengesEnabled && !doesUserHaveFullAccessToCommunity" style="padding-left: 0;">
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="communities-title">
          {{ $t('shp_latest_challenge', { challenge: challengesLabel }) }}
        </div>

        <div class="community-detail__announcements">
          <div class="communities-detail__empty">
            {{ requestAccessToCommunitiesViaChallengeText }}
          </div>

          <DsButton
              v-if="displayRequestAccessButton"
              style="margin-top: 1rem;"
              :label="requestAccessButtonLabel"
              :disabled="hasUserRequestedAccessToCommunity"
              @click="requestAccessToTheCommunity"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Loading from '@/components/Dashboard/ConceptMap/Loading.vue'
import EventCard from '@/components/Simplified/EventCard.vue'
import SimplifiedAnnouncementPreview from '@/components/Simplified/SimplifiedAnnouncementPreview.vue'
import CommunityMixin from '@/util/CommunityMixin'
import TranslationsMixin from '@/util/TranslationsMixin'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '@/store/modules/ui'
import MODAL_IDS from '@/constants/modal-ids'
import { CommunityDetail } from '@/Domain/Community/types'
import { getImageForAnnouncement } from '@/util/helpers'
import { fetchPublicNotifications } from '@/api/notifications'
import { requestAccessToCommunity } from '@/api/communities'
import { ACTION_TYPES } from '@/store/modules/user'
import ChallengeCard from '@/components/Simplified/ChallengeCard.vue'
import { fetchChallenges } from '@/Domain/Challenge/Api/challenges'

export default defineComponent({
  name: "CommunityDetailLastAnnouncementAndEvent",
  components: {
    ChallengeCard,
    SimplifiedAnnouncementPreview,
    EventCard,
    Loading
  },
  props: {
    community: {
      type: Object as PropType<CommunityDetail>,
      required: true
    },
  },
  mixins: [CommunityMixin, TranslationsMixin],
  data() {
    return {
      recentAnnouncements: [],
      upcomingEvents: [],
      recentChallenges: [],
      loadingAnnouncements: false,
      loadingEvents: false,
      isLoadingChallenges: false,
    }
  },
  computed: {
    accessibleChallengeStatusses() {
      return this.$store.getters.accessibleChallengeStatusses
    },
    limitedChallenges() {
      return this.recentChallenges.slice(0, 1); // Returns a copy of the array without mutating the original
    },
    requestAccessToCommunitiesViaChallengeText(): string {
      if (!this.community.challengeCount) {
        return this.$t('community_please_register_view_challenges', {
          challenges: this.challengesLabel
        })
      }

      return this.$t('community_please_register_view_challenges_with_count', {
        count: this.community.challengeCount,
        challenges: this.challengesLabel
      })
    },
    requestAccessButtonLabel(): string {
      if (this.hasUserRequestedAccessToCommunity) {
        return this.$t('community_requested_access')
      }

      return this.$t('community_request_access')
    },
    requestAccessToCommunitiesViaAnnouncementText(): string {
      if (!this.community.announcementCount) {
        return this.$t('community_please_register_view_announcements', {
          announcements: this.announcementsLabel
        })
      }

      return this.$t('community_please_register_view_announcements_with_count', {
        count: this.community.announcementCount,
        announcements: this.announcementsLabel
      })
    },
    requestAccessToCommunitiesViaEventText(): string {
      if (!this.community.eventCount) {
        return this.$t('community_please_register_view_events', { events: this.eventsLabel })
      }

      return this.$t('community_please_register_view_events_with_count', {
        count: this.community.eventCount,
        events: this.eventsLabel
      })
    },
  },
  mounted() {
    if (this.doesUserHaveFullAccessToCommunity) {
      this.fetchRecentAnnouncementsAndEvents()
      this.fetchCommunityChallenges()
    }

    //this.$bus.on('announcementCreated', this.fetchRecentAnnouncementsAndEvents) // This is currently removed functionality, but needs to be checked with Ingrid
  },
  beforeUnmount() {
    //this.$bus.off('announcementCreated', this.fetchRecentAnnouncementsAndEvents)
  },
  methods: {
    getImageForAnnouncement,
    isChallengeAccessibleForUser(challenge) {
      if (!challenge) {
        return false
      }

      if (this.isMember) {
        return true
      }

      return this.accessibleChallengeStatusses.includes(challenge.status)
    },
    requestAccessToTheCommunity() {
      requestAccessToCommunity(this.community.id)
          .then(() => {
            this.$store.dispatch(ACTION_TYPES.REFRESH_PROFILE)
          })
          .catch(error => {
            console.log(error)
          })
    },
    fetchRecentAnnouncementsAndEvents() {
      const filters = { communities: this.community.id }

      this.loadingAnnouncements = true

      fetchPublicNotifications(1, 0, { ...filters, is_event: false })
          .then(items => {
            this.loadingAnnouncements = false
            this.recentAnnouncements = items
          })
          .catch(error => {
            this.loadingAnnouncements = false
            console.log(error)
          })

      this.loadingEvents = true

      fetchPublicNotifications(2, 0, { ...filters, is_event: true, upcoming_events: true })
          .then(items => {
            this.loadingEvents = false
            this.upcomingEvents = items
          })
          .catch(error => {
            this.loadingEvents = false
            console.log(error)
          })
    },
    fetchCommunityChallenges() {
      fetchChallenges({ limit: 1, offset: 0, filters: { communityId: this.community.id } })
          .then(challenges => {
            this.recentChallenges = challenges
          })
          .catch(error => {
            console.log(error)
          })
    },
    createAnnouncement(type) {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        announcement: {
          is_event: (type === 'event'),
          communities: [{
            label: this.community.name,
            value: this.community.id,
          }],
        },
      })
      if (type === 'event') {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_EVENT)
      } else {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_ANNOUNCEMENT)
      }
    },
  }
})
</script>

<style scoped lang="scss">
.communities-detail__empty {
  /*font-size: 14px;
  text-align: center;
  line-height: 85px;
  margin: auto;*/
}
</style>
