<template>
  <div class="portfolios fixed-heading">
    <div class="heading">
      <div class="portfolios__title">
        <h1>Portfolios</h1>
        <p class="guide_text">Organize, categorize and benchmark actor profiles.</p>
      </div>
      <benchmark-dropdown
        class="benchmark__dropdown "
        :options="portfolios"
        :selection="selection"
        icon="chevron-right"
        variant="secondary"
        size="small"
        label="Benchmark"
        @update:modelValue="updateSelection"
        @submit="benchmark"
      />
      <div class="explore-content__filter-button-container portfolios__filter-button">
        <div class="explore-content__filter-indicator" v-if="keywords.length > 0 && keywords.find((f) => f.facet !== 'actor_type')"></div>
        <ds-button
          variant="minimal" size="small" label="Filter" icon="filter-box" class="explore-content__filter-button"
          :class="{ 'explore-content__filter-button-active' : this.$store.state.filters.filter.visible }" @click="toggleFilter"/>
      </div>
    </div>
    <portfolio-filters :portfolios="portfolios" @filters="updateFilters" @portfolioSearch="searchBarValue" :disabled="fetching"/>
    <div class="scrollable has-padding">
      <div class="row portfolios__list" v-if="favourites !== undefined && favourites.length > 0 && searchFieldEnabled == false">
        <div class="portfolios__group-title-container">
          <h1 class="portfolios__group-title">My Favourites</h1>
          <div class="portfolio_navigation">
            <ds-button class="button--small rotate-arrow" icon="chevron-right" :disabled="favouritesArrowDisabled.left" variant="link" @click="previousFavourite()"/>
            <ds-button class="button--small" icon="chevron-right" variant="link" :disabled="favouritesArrowDisabled.right" @click="nextFavourite()"/>
          </div>
        </div>
        <div class="navigation__divider"></div>
        <div class="portfolios__item" v-for="portfolio in favourites">
          <div
            class="card card--portfolio"
            :class="{ 'card--active': selection.includes(portfolio.id), 'portfolio--card--default': portfolio.virtual || portfolio.published || portfolio.isPublishedViaApi, 'building_portfolio': portfolio.building == true  }">
            <div class="portfolio__favourite" @click.prevent="toggleFavourite(portfolio.favourited, portfolio)">
              <ds-button class="button--small favourite-star-active" icon="star" variant="minimal" v-if="portfolio.favourited == true"/>
              <ds-button class="button--small favourite-star" icon="star-outline" variant="minimal" v-else/>
            </div>
            <div class="portfolio__agents" v-if="canUseAgents && ! portfolio.virtual">
              <ds-button class="button--small agent-button" icon="agent" variant="minimal" @click.prevent="showAgentsSidePanel(portfolio)"/>
            </div>
            <div class="portfolio__edit" v-if="canUserEditPortfolio(portfolio)">
              <ds-button class="button--small edit-button" icon="cog" variant="minimal" @click.prevent="edit(portfolio)"/>
            </div>
            <div class="portfolio__edit" v-else>
              <ds-button class="button--small edit-button" icon="cog" variant="minimal" @click.prevent="showSidePanel(portfolio)"/>
            </div>
            <label class="card__image embed-responsive" :style="cover(portfolio)" @click.prevent="show(portfolio)">
              <div class="pull-right portfolio__shared">
                <badge variant="primary--dark" class="portfolio__building__badge" name="Building" v-if="portfolio.building == true"/>
                <badge variant="primary--dark" :name="portfolioActorCount(portfolio)" v-else-if="portfolio.virtual == false"/>
                <badge variant="secondary" :name="visibility(portfolio)"/>
              </div>
            </label>
            <div @click.prevent="show(portfolio)">
              <div
                class="card__title default_portfolio_text" v-tooltip.top="portfolio.name" :title="portfolio.name" v-if="portfolio.virtual == true || portfolio.published == true">
                {{ portfolio.name }}
              </div>
              <div class="card__title " v-tooltip.top="portfolio.name" :title="portfolio.name" v-else>
                {{ portfolio.name }}
              </div>
              <div class="portfolio__card__description guide_text" :title="portfolio.description" v-if="portfolio.description">
                {{ portfolio.description }}
              </div>
              <div class="portfolio__card__description" :title="portfolio.description" style="height:13px;" v-else>
                {{ portfolio.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row portfolios__list" v-if="latest !== undefined && latest.length > 0 && searchFieldEnabled == false">
        <div class="portfolios__group-title-container">
          <h1 class="portfolios__group-title">Last Created & Updated</h1>
          <div class="portfolio_navigation">
            <ds-button class="button--small rotate-arrow" icon="chevron-right" :disabled="latestArrowDisabled.left" variant="link" @click="previousLatests()"/>
            <ds-button class="button--small" icon="chevron-right" variant="link" :disabled="latestArrowDisabled.right" @click="nextLatests()"/>
          </div>
        </div>
        <div class="navigation__divider"></div>
        <div class="portfolios__item" v-for="portfolio in latest">
          <div
            class="card card--portfolio"
            :class="{ 'card--active': selection.includes(portfolio.id), 'portfolio--card--default': portfolio.virtual || portfolio.published || portfolio.isPublishedViaApi, 'building_portfolio' : portfolio.building == true }">
            <div class="portfolio__favourite" @click.prevent="toggleFavourite(portfolio.favourited, portfolio)" v-if="! portfolio.virtual">
              <ds-button class="button--small favourite-star-active" icon="star" variant="minimal" v-if="portfolio.favourited == true"/>
              <ds-button class="button--small favourite-star" icon="star-outline" variant="minimal" v-else/>
            </div>
            <div class="portfolio__agents" v-if="canUseAgents && ! portfolio.virtual">
              <ds-button class="button--small agent-button" icon="agent" variant="minimal" @click.prevent="showAgentsSidePanel(portfolio)"/>
            </div>
            <div class="portfolio__edit" v-if="canUserEditPortfolio(portfolio)">
              <ds-button class="button--small edit-button" icon="cog" variant="minimal" @click.prevent="edit(portfolio)"/>
            </div>
            <div class="portfolio__edit" v-else-if="! portfolio.virtual">
              <ds-button class="button--small edit-button" icon="cog" variant="minimal" @click.prevent="showSidePanel(portfolio)"/>
            </div>
            <label class="card__image embed-responsive" :style="cover(portfolio)" @click.prevent="show(portfolio)">
              <div class="pull-right portfolio__shared">
                <badge variant="primary--dark" class="portfolio__building__badge" name="Building" v-if="portfolio.building == true"/>
                <badge variant="primary--dark" :name="portfolioActorCount(portfolio)" v-else-if="portfolio.virtual == false"/>
                <badge variant="secondary" :name="visibility(portfolio)"/>
              </div>
            </label>
            <div @click.prevent="show(portfolio)">
              <div
                class="card__title default_portfolio_text" v-if="portfolio.virtual == true || portfolio.published == true"
                v-tooltip.top="portfolio.name" :title="portfolio.name">
                {{ portfolio.name }}
              </div>
              <div class="card__title " v-tooltip.top="portfolio.name" :title="portfolio.name" v-else>
                {{ portfolio.name }}
              </div>
              <div class="portfolio__card__description guide_text" :title="portfolio.description" v-if="portfolio.description">
                {{ portfolio.description }}
              </div>
              <div class="portfolio__card__description" :title="portfolio.description" style="height:13px;" v-else>
                {{ portfolio.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row portfolios__list">
        <h1 class="portfolios__group-title">All Portfolios</h1>
        <div class="navigation__divider"></div>
        <div class="portfolios__item" v-if="!fetching" v-for="portfolio in portfolios">
          <div
            class="card card--portfolio"
            :class="{ 'card--active': selection.includes(portfolio.id), 'portfolio--card--default': portfolio.virtual || portfolio.published || portfolio.isPublishedViaApi, 'building_portfolio' : portfolio.building == true }">
            <div class="portfolio__favourite" @click.prevent="toggleFavourite(portfolio.favourited, portfolio)" v-if="! portfolio.virtual">
              <ds-button class="button--small favourite-star-active" icon="star" variant="minimal" v-if="portfolio.favourited == true"/>
              <ds-button class="button--small favourite-star" icon="star-outline" variant="minimal" v-else/>
            </div>
            <div class="portfolio__agents" v-if="canUseAgents && ! portfolio.virtual">
              <ds-button class="button--small agent-button" icon="agent" variant="minimal" @click.prevent="showAgentsSidePanel(portfolio)"/>
            </div>
            <div class="portfolio__edit" v-if="canUserEditPortfolio(portfolio)">
              <ds-button class="button--small edit-button" icon="cog" variant="minimal" @click.prevent="edit(portfolio)"/>
            </div>
            <div class="portfolio__edit" v-else-if="! portfolio.virtual">
              <ds-button class="button--small edit-button" icon="cog" variant="minimal" @click.prevent="showSidePanel(portfolio)"/>
            </div>
            <label class="card__image embed-responsive" :style="cover(portfolio)" @click.prevent="show(portfolio)">
              <div class="pull-right portfolio__shared">
                <badge variant="primary--dark" class="portfolio__building__badge" name="Building" v-if="portfolio.building == true"/>
                <badge variant="primary--dark" :name="portfolioActorCount(portfolio)" v-else-if="portfolio.virtual == false"/>
                <badge variant="secondary" :name="visibility(portfolio)"/>
              </div>
            </label>
            <div @click.prevent="show(portfolio)">
              <div
                class="card__title default_portfolio_text" v-tooltip.top="portfolio.name" :title="portfolio.name" v-if="portfolio.virtual == true || portfolio.published">
                {{ portfolio.name }}
              </div>
              <div class="card__title " v-tooltip.top="portfolio.name" :title="portfolio.name" v-else>
                {{ portfolio.name }}
              </div>
              <div class="portfolio__card__description guide_text" :title="portfolio.description" v-if="portfolio.description">
                {{ portfolio.description }}
              </div>
              <div class="portfolio__card__description" :title="portfolio.description" style="height:13px;" v-else>
                {{ portfolio.description }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <loading
            v-if="fetching"
            style="margin-left: 50px;"/>
        </div>
      </div>
    </div>
    <hover-overlay-button
      v-if="!$store.getters.isPortfolioMember"
      class="application-plus-btn" @openPanel="createPortfolio"
      label="Create portfolio"
      orientation="right"></hover-overlay-button>
  </div>
</template>


<script>
  import { favouritePortfolio, fetchFavourite, fetchLatest, fetchPortfolio, unfavouritePortfolio } from '../api/portfolios'

  import Checkbox from '../components/Form/Checkbox.vue'
  import SubtleDropdown from '../components/Dropdown/SubtleDropdown.vue'
  import Badge from '../components/Badge/Badge.vue'
  import CheckboxButton from '../components/CheckboxButton/CheckboxButton.vue'
  import BenchmarkDropdown from '../components/Dropdown/BenchmarkDropdown.vue'
  import PortfolioFilters from '../components/Filters/PortfolioFilters.vue'
  import Loading from '../components/Dashboard/ConceptMap/Loading.vue'

  import { viewDashboardOptions } from '../constants/config'

  import { MUTATION_TYPES as FILTER_MUTATION_TYPES } from '../store/modules/filters'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui'
  import { MUTATION_TYPES as PORTFOLIO_MUTATION_TYPES } from '../store/modules/managePortfolio'
  import HoverOverlayButton from '../components/Overlay/HoverOverlayButton.vue'
  import { SiteTemplate } from '../store/modules/config.js'
  import { getDefaultFallbackImageUrl } from '../util/helpers.js'
  import debounce from 'lodash/debounce'

  export default {
    name: 'portfolios',
    data () {
      return {
        selection: [],
        errors: {},
        portfolios: this.$root.cache$portfolios || [],
        favourites: [], // Will contain all the favourited portfolios
        favouritesOffset: 0, // Defines the offset for the favourites navigation
        latest: [], // Will contain the latest created and updated portfolios
        latestOffset: 0, // Defines the offset for the latest navigation
        maxVisiblePortfolios: 4, // Defines how many portfolios can be viewed in 1 row
        favouritesArrowDisabled: { // Defines the enable state of the navivations arrows
          left: true,
          right: false,
        },
        latestArrowDisabled: { // Defines the enable state of the navivations arrows
          left: true,
          right: false,
        },
        filters: {}, // Will contain the filters that will be used to fetch the portfolios
        searchFieldEnabled: false, // Defines if the 'Favourites' and 'Latest' groups are visible when searching in the search bar
        fetching: false,
      }
    },
    computed: {
      canUseAgents () {
        return (this.isOwner || this.isTeamMember) && this.$store.getters.hasAccessToAgents
      },
      dashboardLink () {
        const viewDashboard = this.$store.state.config.viewDashboard
        return (viewDashboard || []).length > 1 ? '/dashboards/' + viewDashboard[1]
          : (viewDashboard || []).length ? '/dashboards/' + viewDashboard[0]
            : '/actors'
      },
      dashboards () {
        const selected = this.$store.state.config.viewDashboard || []
        const links = viewDashboardOptions.filter(opt => selected.includes(opt.value) &&
          !['home', 'sankey', 'compare', 'competitive-overview'].includes(opt.value))
          .map(({ icon, label, value }) => ({ icon, label, value: '/dashboards/' + value }))
        return [{
          icon: 'format-list-bulleted',
          label: 'Directory',
          value: '/actors',
        }].concat(links)
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember
      },
      keywords () {
        return this.$store.state.filters.keywords
      },
      hidePlusButton () {
        return this.$route.path === '/settings/users' ||
          (this.$route.name === 'portfolio-detail' && this.$route.params.id !== 'create') ||
          this.$route.path === '/profile/update' ||
          this.$route.path.startsWith('/settings') ||
          this.$route.path.startsWith('/datalab') ||
          this.$route.name === 'gdpr-terms' ||
          ['announcements-simplified', 'announcements-detail', 'challenges', 'challenges-detail', 'actors-simplified', 'products-simplified'].includes(this.$route.name) ||
          (this.$route.name === 'actor-detail' && [SiteTemplate.SIMPLIFIED, SiteTemplate.NEW_SIMPLIFIED].includes(this.$store.state.config.siteTemplate)) ||
          !this.$store.getters.isLoggedIn ||
          this.isOnProductPage ||
          this.isExplorationOnly
      },
    },
    methods: {
      getDefaultFallbackImageUrl,
      portfolioActorCount (portfolio) {
        if (portfolio.member_count) {
          return portfolio.member_count + ' actors'
        }

        return '0 actors'
      },
      canUserEditPortfolio (portfolio) {
        return this.canEdit(portfolio)
      },
      createPortfolio () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'create-portfolio' })
      },
      toggleFavourite (isFavourited, portfolio) {
        this.reset()
        if (isFavourited == true) {
          // remove portfolio from favourites
          unfavouritePortfolio(portfolio.id).then(unfavourite => {
            this.fetchFavourites()
            this.fetchLatests()
            this.fetchPortfolios()
          }).catch(errors => {
            // console.log(errors)
          })
        } else {
          // add portfolio to favourites
          favouritePortfolio(portfolio.id).then(favourite => {
            this.fetchFavourites()
            this.fetchLatests()
            this.fetchPortfolios()
          }).catch(errors => {
            console.log(errors)
          })
        }
      },
      benchmark () {
        window.location.href = '/dashboards/charts?portfolios=' + this.selection.join(',')
      },
      updateSelection (value) {
        this.selection = value
      },
      visibility (portfolio) {
        return portfolio.published || portfolio.isPublishedViaApi ? 'published' : portfolio.is_immutable ? 'immutable' : portfolio.virtual ? 'Default' : portfolio.private ? 'Private' : 'Shared'
      },
      canEdit (portfolio) {
        if (portfolio.virtual || portfolio.is_immutable) {
          return false
        }

        if (this.isOwner) {
          return true
        }

        if (!portfolio.private && this.isTeamMember) {
          return true
        }

        var userPortfolios = this.$store.state.user.profile.portfolios
        var contributePortfolios = []

        if (this.$store.getters.isPortfolioMember) {
          contributePortfolios = this.$store.state.user.profile.contributePortfolios
        }

        if (contributePortfolios.includes(portfolio.id)) {
          return true
        }

        return (userPortfolios &&
          userPortfolios.length &&
          userPortfolios.includes(portfolio.id))
      },
      nextFavourite () {
        // Move forwards on the favourite portfolios navigation
        this.favouritesOffset++
        this.fetchFavourites()
      },
      previousFavourite () {
        // Move backwards on the favourite portfolios navigation
        if (this.favouritesOffset > 0) {
          this.favouritesOffset--
        }

        if (this.favouritesOffset == 0) {
          // Disable the left arrow if the favourites offset is 0
          this.favouritesArrowDisabled.left = true
        }
        this.fetchFavourites()
      },
      fetchFavourites (numbOfVisiblePortfolios, offset, filters) {
        // Get the favourited portfolios to be viewed in the page and set its offset
        // If the passed values are undefined then they will set with the values of the data properties
        if (numbOfVisiblePortfolios == undefined) {
          numbOfVisiblePortfolios = this.maxVisiblePortfolios
        }
        if (offset == undefined) {
          offset = this.favouritesOffset
        }
        if (filters == undefined) {
          filters = this.filters
        }
        fetchFavourite(numbOfVisiblePortfolios, offset, filters).then(favourites => {
          // If the favourites are smaller than the limit, then we know we have not fetched any new information
          // There's no need to update the information in that case and the right arrow can be disabled
          // If the offset is 0, also assign the fetched data to our favourites array
          if (this.favourites.length == 0 || favourites.length == this.maxVisiblePortfolios || offset == 0) {
            if (offset > 0) {
              this.favouritesArrowDisabled.left = false
            }

            this.favourites = favourites
            this.favouritesArrowDisabled.right = false
          } else {
            this.favouritesArrowDisabled.right = true
          }
        }).catch(errors => {
          console.log(errors)
        })
      },
      fetchLatests (numbOfVisiblePortfolios, offset, filters) {
        // Fetch the latest created and updated portfolios
        // If the passed values are undefined then they will set with the values of the data properties
        if (numbOfVisiblePortfolios == undefined) {
          numbOfVisiblePortfolios = this.maxVisiblePortfolios
        }
        if (offset == undefined) {
          offset = this.latestOffset
        }
        if (filters == undefined) {
          filters = this.filters
        }
        fetchLatest(numbOfVisiblePortfolios, offset, filters).then(latest => {
          // If the latest are smaller than the limit, then we know we have not fetched any new information
          // There's no need to update the information in that case and the right arrow can be disabled
          if (this.latest.length == 0 || latest.length == this.maxVisiblePortfolios) {
            if (offset > 0) {
              this.latestArrowDisabled.left = false
            }
            this.latest = latest
            this.latestArrowDisabled.right = false
          } else {
            this.latestArrowDisabled.right = true
          }
        }).catch(errors => {
          console.log(errors)
        })
      },
      fetchPortfolios: debounce(function (filters) {
        if (this.fetching) {
          return
        }
        this.fetching = true
        // The portfolio will be fetched based on the filters that are passed
        // If there is no filters than it will return all the portfolios
        if (filters == undefined) {
          filters = this.filters
        }
        fetchPortfolio(filters).then(portfolios => {
          this.portfolios = portfolios
          this.$root.cache$portfolios = portfolios
          this.fetching = false
        }).catch(errors => {
          console.log(errors)
        })
      }, 500),
      nextLatests () {
        // Move forwards on the updated portfolios navigation
        this.latestOffset++
        this.fetchLatests()
      },
      previousLatests () {
        // Move backwards on the updated portfolios navigation
        if (this.latestOffset > 0) {
          this.latestOffset--
        }

        if (this.latestOffset == 0) {
          // Disable the left arrow if the favourites offset is 0
          this.latestArrowDisabled.left = true
        }
        this.fetchLatests()
      },
      edit (portfolio) {
        this.$router.push('/portfolios/' + portfolio.id)

        // This is in a timeout to prevent the "EditPortfolioPanel" from having undefined as the router.params.id
        // Ideally the UI of updating the portfolio is refactored into its own component, making it so we can safely invoke the side panel when the route has finished routing, which is now simulated with setTimeout
        setTimeout(() => {
          this.showSidePanel(portfolio)
        })
      },
      showSidePanel (portfolio) {
        this.$store.commit('FILTERS/SET_PORTFOLIO', portfolio.id)
        this.$store.commit(PORTFOLIO_MUTATION_TYPES.SET_ACTIVE_PORTFOLIO, portfolio)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'edit-portfolio', metaData: { id: portfolio.id }})
      },
      showAgentsSidePanel (portfolio) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'portfolio-agents', metaData: { id: portfolio.id }})
      },
      select (portfolio) {
        this.$store.commit('FILTERS/CLEAR')
        this.$store.commit('FILTERS/SET_PORTFOLIO', portfolio.id)
        this.$store.commit(PORTFOLIO_MUTATION_TYPES.SET_ACTIVE_PORTFOLIO, portfolio)
      },
      show (portfolio) {
        // In case the user is a portfolio memeber set as a benchmarker, the user cannot set the portfolio as a filter.
        if (this.$store.state.user.profile.roleOnCurrentTeam == 'portfolio_member') {
          if (this.$store.state.user.profile.benchmarkPortfolios !== undefined) {
            if (!this.$store.state.user.profile.benchmarkPortfolios.includes(portfolio.id)) {
              this.select(portfolio)
              this.$router.push('/actors')
            }
          }
        } else {
          this.select(portfolio)
          this.$router.push('/actors')
        }
      },
      cover (portfolio) {
        return {
          backgroundImage: 'url(' + (portfolio.image || this.getDefaultFallbackImageUrl()) + ')',
        }
      },
      options (portfolio) {
        return this.dashboards
      },
      updateFilters (newFilters) {
        // The selected filters in the portfolio filters will update the filters data property and fetch the portfolios according to the filters
        // All offset and portfolio containers must be reset
        this.filters = newFilters
        this.favouritesOffset = 0
        this.latestOffset = 0
        this.latest = []
        this.favourites = []
        this.portfolios = []

        this.fetchFavourites(this.maxVisiblePortfolios, this.favouritesOffset, this.filters)
        this.fetchLatests(this.maxVisiblePortfolios, this.latestOffset, this.filters)
        this.fetchPortfolios()
      },
      // Reset the offsets of the favourites and latest updated arrays
      reset () {
        this.favouritesOffset = 0
        this.latestOffset = 0
      },
      searchBarValue (searchValue) {
        // This will fetch the portfolios based on the inserted input in the search bar.
        // The 'searchFieldEnabled' data property will control the visibility of the 'Favourites' and 'Latest' portfolio groups
        if (searchValue.query == undefined) {
          this.searchFieldEnabled = false
        } else {
          this.searchFieldEnabled = true
        }

        var nameFilter = Object.assign(searchValue, this.filters)
        this.fetchPortfolios(nameFilter)
      },
      toggleFilter () {
        // Will toggle the visibility of the filters below the portfolio search bar
        this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, !this.$store.state.filters.filter.visible)
      },
    },
    beforeRouteEnter (to, from, next) {
      if (!window.config.views || window.config.views.includes('portfolios')) {
        next()
      } else {
        next('/')
      }
    },
    beforeUnmount () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
    },
    mounted () {
      if (this.isPortfolioMember) {
        this.$router.push('/')
      }

      this.fetchPortfolios()
      this.fetchFavourites()
      this.fetchLatests()

      this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, true)

      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      this.$store.commit(UI_MUTATION_TYPES.TOGGLE_SIDE_PANEL, true)
    },
    components: {
      Checkbox,
      SubtleDropdown,
      Badge,
      CheckboxButton,
      BenchmarkDropdown,
      PortfolioFilters,
      HoverOverlayButton,
      Loading,
    },
  }
</script>

<style lang="scss">
  @import "../../scss/_variables.scss";

  .portfolios__title {
    display: inline-block;
    max-width: 70%;
  }

  .benchmark__dropdown {
    display: inline-block;
    float: right;

    .benchmark__dropdown__button {
      .button-secundary {
        path {
          fill: white;
        }
      }

      .svg-icon {
        width: 30px;
        height: 30px;
      }

      .button__label {
        font-size: 16px;
        font-family: $font-stack-primary;
        line-height: 19px;
        font-weight: 300;
        letter-spacing: .06em;
        padding-left: 8px !important;
      }

      padding: 4px 20px 0 30px;
      margin-right: 0px;
    }

    .benchmark-dropdown__options {
      max-width: 157px;

      .button {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .rotate-arrow {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .portfolios__filter-button {
    display: inline-block;
    float: right;
    margin-right: 1em !important;
  }

  .portfolio_navigation {
    display: inline-block;
    float: right;
    margin-right: 1em;
  }

  .portfolios__group-title-container {
    width: 100%;
  }

  .portfolios__group-title {
    color: $color-primary;
    text-transform: uppercase;
    font-size: 20px;
    padding-left: .5em;
    display: inline-block;
    font-family: $font-stack-primary;
    line-height: 29px;
    font-weight: 300;
    letter-spacing: .06em;
  }

  .default_portfolio_text {
    color: white;

  }

  .building_portfolio {
    background-color: $color-background-light-grey !important;
  }

  .favourite-star-active {
    height: 30px;
    width: 30px;
    background-color: white !important;
    border: 1px solid $color-borders !important;

    svg {
      height: 15px;
      width: 15px;
      margin-top: 2px;
    }

    path {
      fill: $color-primary !important;
    }
  }

  .favourite-star-active:hover {
    height: 30px;
    width: 30px;
    background-color: $color-body-borders !important;
    border: 1px solid $color-borders !important;

    svg {
      height: 15px;
      width: 15px;
      margin-top: 2px;
    }

    path {
      fill: $color-text-grey !important;
    }
  }

  .favourite-star {
    height: 30px;
    width: 30px;
    background-color: white !important;
    border: 1px solid $color-borders !important;

    .svg-icon {
      height: 15px;
      width: 15px;
    }
  }

  .favourite-star:hover {
    height: 30px;
    width: 30px;
    background-color: $color-body-borders !important;
    border: 1px solid $color-borders !important;

    .svg-icon {
      height: 15px;
      width: 15px;
    }

    polygon {
      fill: $color-text-grey !important;
      stroke: $color-text-grey !important;
    }
  }

  .default_portfolio_text::after {
    background-color: white !important;
  }

  .portfolio--card--default {
    background-color: $color-primary-darker !important;
  }

  .portfolios__list.row {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 30px;
  }

  .portfolios__item {
    padding-right: 10px;
    padding-left: 10px;
    width: 300px;
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }

  .card--portfolio {
    // overflow: hidden;
    position: relative;

    &:hover {
      border-color: black;
    }

    .card__title {
      margin: 1rem 0 0;
      max-width: 260px;
      font-size: 0.9em;
      white-space: pre-wrap;
      text-overflow: unset;
    }

    .portfolio__card__description {
      position: relative;
      z-index: 1;
      line-height: 1.4em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 1em 0;

    }

    .embed-responsive {
      padding-bottom: 160px;
      overflow: visible;
    }

    .card__image {
      display: block;
      margin: -1rem -1rem 0;
      border-bottom: 1px solid #ccc;
      border-color: inherit;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      background-color: white;
      background-size: cover;
    }

    .portfolio__building__badge {
      background-color: $color-alternative-three !important;
    }
  }

  .card.card--dashed {
    min-height: 259px;
    border-style: dashed;

    .card__image {
      border-bottom-style: dashed;
    }
  }

  .card.card--active {
    border-color: black;
  }

  .card__links {
    padding-top: 1rem;
    margin: -4px -12px -12px;
  }

  .portfolio__shared {
    bottom: -1em;
    line-height: 26px;
    font-size: 12px;
    right: 10px;
    position: absolute;
  }

  .portfolio__edit {
    top: 0.5em;
    right: 40px;
    position: absolute;
    z-index: 1;
  }

  .portfolio__agents {
    top: 0.5em;
    right: 70px;
    position: absolute;
    z-index: 1;
  }

  .edit-button {
    background-color: white !important;
    border: 1px solid $color-borders !important;

    path {
      fill: $color-text-grey-light !important;
    }
  }

  .edit-button:hover {
    background-color: $color-body-borders !important;
    border: 1px solid $color-borders !important;

    path {
      fill: $color-text-grey !important;
    }
  }

  .agent-button {
    background-color: white !important;
    border: 1px solid $color-borders !important;

  }

  .agent-button:hover {
    background-color: $color-body-borders !important;
    border: 1px solid $color-borders !important;
  }

  .portfolio__favourite {
    top: 0.5em;
    right: 10px;
    position: absolute;
    z-index: 1;
  }

  .portfolio__follow {
    left: 0.5em;
  }

  .card__checkbox {
    position: absolute;
    top: 0;
    left: 0;
    text-align: right;

    > .radio {
      position: relative;
      z-index: 2;
      margin: 5px;
    }

    > .radio > input {
      width: 24px;
      height: 24px;
    }

    > .radio::after {
      content: '';
      position: relative;
      z-index: 1;
    }
  }
</style>
