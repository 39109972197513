<template>
  <div
    class="svg-icon"
    :class="classes"
    :style="{ fill: fill }"
    v-html="svgMarkup"
    @click="$emit('click', $event)"
  />
</template>

<script lang="ts">
  import manifest from '../../../icons/manifest.json'
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      name: {
        type: String,
        validator: value => Object.keys(manifest).includes(value)
      },
      size: {
        type: String,
        validator: value => ['regular', 'large', 'small', 'extra-small', '12', '14', '18', '22'].includes(value),
        default: 'regular'
      },
      noFill: {
        type: Boolean,
        default: false
      },
      fill: {
        type: String,
        default: 'currentColor'
      }
    },
    emits: ['click'],
    computed: {
      svgMarkup: function () {
        return manifest[this.name]
      },
      classes () {
        var classes = {
          [`svg-icon--${this.size}`]: this.size,
          [`svg-icon--${this.name}`]: this.name,
        };

        if (this.noFill) {
          classes['svg-icon--no-fill'] = true
        }

        return classes
      },
    }
  })
</script>
