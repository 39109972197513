<template>
  <div class="community-detail-members__container">
    <div
      v-for="(member, index) in members"
      class="actor-contributor"
      :key="'member' + index"
    >
      <CommunityMemberCard
        :member="member"
        :show-actions="false"
        :show-department="false"
        :show-experience="false"
        :compact="true"
        :show-organization-title="false"
        :show-organization-link="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import Avatar from '../Avatar/Avatar.vue'

  import { actorLogo } from '../../util/actor'
  import { capitalize } from '../../util/string.ts'
  import { defineComponent } from 'vue'
  import { UserBaseInfoData } from '@/Domain/User/Types/types'
  import { PropType } from '~/vue'
  import CommunityMemberCard from '@/components/Communities/CommunityMemberCard.vue'

  export default defineComponent({
    name: 'CommunityDetailMembersCard',
    props: {
      members: {
        type: Array as PropType<UserBaseInfoData[]>,
      }
    },
    methods: {
      capitalize,
      actorLogo,
      goToActorDetailPage(actorId) {
        this.$router.push({
          params: { section: 'members', id: actorId },
        })
      },
    },
    components: {
      CommunityMemberCard,
      Avatar,
    },
  })
</script>

<style lang="scss" scoped>
  .community-detail-members__container {
    display: flex;
    flex-direction: column;
  }

  .community-detail-member__table {
    border: 0;
  }

  .community-detail-member__member-row {
    height: 36px;
    border: 0;

    &:nth-child(even) {
      background-color: #F2F2F2;
    }
  }

  // for long list claimed community actors
  .community-detail-member__table_row {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
  }
</style>
