import objectPath from 'object-path'
import { fetchConfig } from '../../api/config'

const customChatbotConfigurations = {
  'ariadne': {
    'chatbotCTA': 'Ask Ellie',
    'chatbotEmptyStateMessage': 'Ask Ellie anything...',
    'placeholder': 'Ask Ellie a question...',
    'assistantName': 'Ellie'
  },
  'local': {
    'chatbotCTA': 'Ask Chatty',
    'chatbotEmptyStateMessage': 'Ask Chatty anything...',
    'placeholder': 'Ask Chatty a question...',
    'assistantName': 'Chatty'
  },
  'foodleap': {
    'chatbotCTA': 'Ask Foodleap',
    'chatbotEmptyStateMessage': 'Ask Foodleap',
    'placeholder': 'Ask Foodleap a question...',
    'assistantName': 'Foodleap'
  },
}

const possibleFutureConfig = {
  primaryColor: '#00c3af75',
  hex: [
    '#FFEE57',
    '#F9A825',
    '#FF6F00',
    '#BF360C',
    '#FF3D00',
    '#FF1744',
    '#E1BEE7',
    '#AB47BC',
    '#4A148C',
    '#F48FB1',
    '#C2185B',
    '#D500F9',
    '#BBDEFB',
    '#1976D2',
    '#2962FF',
    '#84FFFF',
    '#00B8D4',
    '#006064',
    '#1FDCB8',
    '#009688',
    '#66BB6A',
    '#00E676',
    '#1B5E20',
    '#76FF03',
    '#37474F',
    '#B0BEC5',
    '#4E342E',
    '#BCAAA3',
    '#EEEEEE',
    '#FFFF00',

  ],
  hexBorders: [
    '#FFEE57',
    '#F9A825',
    '#FF6F00',
    '#BF360C',
    '#FF3D00',
    '#FF1744',
    '#E1BEE7',
    '#AB47BC',
    '#4A148C',
    '#F48FB1',
    '#C2185B',
    '#D500F9',
    '#BBDEFB',
    '#1976D2',
    '#2962FF',
    '#84FFFF',
    '#00B8D4',
    '#006064',
    '#1FDCB8',
    '#009688',
    '#66BB6A',
    '#00E676',
    '#1B5E20',
    '#76FF03',
    '#37474F',
    '#B0BEC5',
    '#4E342E',
    '#BCAAA3',
    '#EEEEEE',
    '#FFFF00',
  ],
  actorString: 'Member',
  dashboardType: window.DASHBOARD_TYPE,

  // Deprecated:
  colors: ['yellow', 'purple', 'black', 'teal', 'blue', 'red'],
}

export const SiteTemplate = {
  SIMPLIFIED: 'SIMPLIFIED',
  NEW_SIMPLIFIED: 'NEW_SIMPLIFIED',
  TRADITIONAL: 'TRADITIONAL',
}

export const ACTION_TYPES = {
  REFRESH_CONFIG: 'CONFIG/REFRESH_CONFIG',
}

export const MUTATION_TYPES = {
  CONFIG_SET: 'CONFIG_SET',
}

export const SPOTTING_AREA_TYPES = {
  PUBLIC: 'public',
  RESTRICTED: 'restricted',
  PREMIUM: 'premium',
  PRIVATE: 'private',
}

// All config is optional, so the getters below should foresee defaults
export const getters = {
  canCreateChallenge (state, getters) {
    if (!state.challengesAreEnabled) {
      return false
    }

    if (getters.isMember || getters.isOwner) {
      return true
    }

    return !!state.canCreateChallenge
  },
  canCreatePrivateChallenge (state, getters) {
    if (!state.challengesAreEnabled) {
      return false
    }

    if (getters.isMember || getters.isOwner) {
      return true
    }

    return !!state.canCreatePrivateChallenge
  },
  challengeMatchmakingEnabled (state, getters) {
    if (!state.challengesAreEnabled) {
      return false
    }

    if (getters.isMember || getters.isOwner) {
      return true
    }

    return !!state.challengeMatchmakingEnabled
  },
  accessibleChallengeStatusses (state) {
    if (!state.challengesAreEnabled) {
      return []
    }

    return state.accessibleChallengeStatusses || []
  },
  viewableChallengeStatusses (state) {
    if (!state.challengesAreEnabled) {
      return []
    }

    return state.viewableChallengeStatusses || []
  },
  isGdprDue () {
    return true
  },
  isPortfolioSyncEnabled (state) {
    return state.ecosystemSyncConfigurations && state.ecosystemSyncConfigurations.length > 0
  },
  verifiedEmailDomains (state) {
    return state.verified_email_domains || []
  },
  portfolioSyncConfigurations (state) {
    return state.ecosystemSyncConfigurations
  },
  currentEcosystem (state) {
    return state
  },
  isWear (state) {
    return state.name === 'wear'
  },
  isSoftlanding () {
    return ['softlanding'].includes(state.name.toLowerCase())
  },
  isStaging () {
    return ['own'].includes(state.name.toLowerCase())
  },
  isCommunityEcosystem () {
    return ['community', 'communitynew'].includes(state.name.toLowerCase())
  },
  isFoodleap () {
    return ['foodleap'].includes(state.name.toLowerCase())
  },
  isGreentex () {
    return ['greentex'].includes(state.name.toLowerCase())
  },
  isAgriTech () {
    return ['agritech'].includes(state.name.toLowerCase())
  },
  isSpacewerx () {
    return ['spacewerx'].includes(state.name.toLowerCase())
  },
  isAriadne () {
    return ['ariadne'].includes(state.name.toLowerCase())
  },
  isS4Fashion () {
    return ['s4fashion'].includes(state.name.toLowerCase())
  },
  isKlik () {
    return ['klik'].includes(state.name.toLowerCase())
  },
  isInnovaud () {
    return ['innovaud'].includes(state.name.toLowerCase())
  },
  hasAccessToCommunities (state) {
    return !!state.communities_add_on_enabled
  },
  hasAccessToNewSpottingAreas (state, getters) {
    return (!!state.spotting_areas_add_on_enabled && getters.hasAccessToMonitoring)
  },
  isNewKnowledgeBaseEnabled () {
    return true
  },
  hasAccessToMultiLinguality (state) {
    return !!state.access_to_multi_lingual_content
  },
  isSpottingAreaScopeEnabled (state) {
    return !!state.is_spotting_area_scope_enabled
  },
  isQandaChatBotEnabled (state) {
    return !!state.qanda_chat_add_on_enabled
  },
  isPnG () {
    return ['inqbet-trendmonitoring-pg'].includes(state.name.toLowerCase())
  },
  isOwn (state) {
    return state.name === 'own'
  },
  isCrypto (state) {
    return state.name === 'crypto'
  },
  isSales (state) {
    return state.name === 'sales' || state.name === 'customers'
  },
  isCi (state) {
    return state.name === 'ci'
  },
  isUmicore (state) {
    return [
      'umicore-monitoring',
    ].includes(state.name.toLowerCase())
  },
  ecosystemDisplayName (state) {
    if (state.displayName) {
      return state.displayName
    }

    return state.name
  },
  ecosystemEmail (state) {
    return state.get_in_touch_email
  },
  ecosystemName (state) {
    return state.name
  },
  defaultLegendProperty (state) {
    return state.defaultLegendProperty || 'category'
  },
  legendProperty (state) {
    return state.legendeProperty || 'category'
  },
  productLegendProperty (state, getters) {
    return state.productLegendProperty || getters.legendProperty
  },
  viewMapOptions (state) {
    return state.viewMapOptions || {}
  },
  viewAnalytics (state) {
    return state.viewAnalytics || []
  },
  viewActorTypes (state) {
    return state.viewActorTypes || ['LegalEntity']
  },
  viewProductTypes (state) {
    return state.viewProductTypes || ['Product']
  },
  isProductEnabled (state) {
    return state.viewActorTypes && state.viewActorTypes.includes('Product')
  },
  areChallengesEnabled (state) {
    return !!state.challengesAreEnabled
  },
  areMessageBoardsEnabled (state) {
    if (!state.forIntelligenceAndOperations && !objectPath.has(state, 'messageBoardsAreEnabled')) {
      return true
    }

    return !!state.messageBoardsAreEnabled
  },
  hasAccessToAnnouncementsAndEventsForCommunities (state) {
    return !!state.communities_announcements_add_on_enabled
  },
  views (state) {
    return state.views || ['actors/detail', 'portfolios', 'datalab']
  },
  enabledAnalyticsModules (state) {
    return state.viewAnalytics || []
  },
  canPremiumEnrich (state) {
    return !!state.canPremiumEnrich
  },
  canUseActorAutocomplete (state) {
    // This used to be non-true and based on a the package type, now any ecosystem can use it
    return true
  },

  // The access levels of ecosystems (public or private)
  isPrivate (state) {
    if (state.package_type == 'standard') {
      return (!state.allow_anonymous && !state.allowRegister)
    }

    return state.publisher_add_on_enabled != true || (!state.allow_anonymous && !state.allowRegister)
  },
  isPublic (state) {
    if (state.package_type == 'standard') {
      // The standard package has the publisher feature optional by default
      return !!state.allow_anonymous && !!state.allowRegister
    }

    return state.publisher_add_on_enabled == true && !!state.allow_anonymous && !!state.allowRegister
  },
  allowRegister (state) {
    return !!state.allowRegister
  },
  isPublisherEnabled (state) {
    return state.publisher && state.publisher.public_fields
  },
  canActorsBeContacted (state) {
    if (state.package_type == 'standard') {
      // The standard package has the publisher feature optional by default
      return !!state.actorsCanBeContacted
    }
    return state.publisher_add_on_enabled == true && !!state.actorsCanBeContacted
  },
  canRegister (state) {
    if (state.package_type == 'standard') {
      // The standard package has the publisher feature optional by default
      return !!state.publisher && state.publisher.public_fields && state.allowRegister
    }

    if (state.publisher_add_on_enabled != true) {
      return false
    }

    return !!state.allowRegister || !!state.allowCuratedAdd
  },
  allowCuratedAdd (state) {
    return !!state.allowCuratedAdd
  },
  canUseBuzz (state) {
    return !!state.sensing_add_on_enabled
  },
  canUsePublicAnnouncements (state, getters) {
    if (!getters.areMessageBoardsEnabled) {
      return false
    }

    // Return by default, in case the user is an owner or when the permission doesn't exist
    if (!state.hasOwnProperty('canViewPublicAnnouncements')) {
      return true
    }

    // Otherwise, return the permission's value
    return !!state.canViewPublicAnnouncements
  },
  canCreateAnnouncement (state, getters) {
    if (getters.isActor && state.hasOwnProperty('canCreateAnnouncement')) {
      return !!state.canCreateAnnouncement
    }

    return true
  },
  hasScores (state) {
    return !!state.hasScores && state.scores_add_on_enabled == true
  },
  hasCustomScores (state) {
    return !!state.hasCustomScores && state.scores_add_on_enabled == true
  },
  scoresAvailable (state) {
    return !!state.scoresAvailable && state.scores_add_on_enabled == true
  },
  isCompetitive (state) {
    return state.hasReferenceActor == true
  },
  knowledgeSharing (state) {
    return state.knowledgeSharing ? !!state.knowledgeSharing.enabled : false
  },
  publisher (state) {
    return !!state.publisher
  },
  ecosystemMemberPermissions (state) {
    // Return the permissions set for the Ecosystem Member (i.e. publisher property)
    if (!state.publisher) {
      return {}
    }

    return state.publisher
  },
  publicFields (state) {
    if (state.permissions && state.permissions.visitor && state.permissions.visitor.public_fields) {
      return state.permissions.visitor.public_fields
    }

    return []
  },
  publicFieldsForRole (state) {
    return state.public_fields
  },
  isMonthlyEnrichmentEnabled (state) {
    return state.monthly_enrichment == true
  },

  canShowActorDetail (state, getters) {
    return getters.views.includes('actors/detail')
  },
  // Double of knowledgeSharing, but this time a more verbose function name
  reportsEnabled (state, getters) {
    return state.knowledgeSharing ? !!state.knowledgeSharing.enabled : false
  },

  home (state, getters) {
    if ([SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(getters.activeSiteTemplate)) {
      return '/dashboards/home'
    }

    return (state.viewDashboard || []).length ? '/dashboards/' + state.viewDashboard[0] : '/actors'
  },
  dashboardType (state) {
    return state.dashboardType
  },

  actorString (state) {
    return state.actorString
  },
  actorStrings (state) {
    return state.actorString + 's'
  },
  anActorString (state) {
    return 'a ' + state.actorString.toLowerCase()
  },
  primaryColor (state) {
    return state.primaryColor || possibleFutureConfig.primaryColor
  },
  getUrl (state) {
    return state.url
  },
  getCarrouselPortfolio (state) {
    return state.presentationModePortfolio
  },
  reportSettings (state) {
    return state.report_settings || []
  },
  extendedTaxonomiesEnabled (state, getters) {
    return (getters.expertiseLabels && getters.expertiseLabels.length > 0) ||
      (getters.processStepLabels && getters.processStepLabels.length > 0) ||
      (getters.aspectLabels && getters.aspectLabels.length > 0) ||
      (getters.motivationLabels && getters.motivationLabels.length > 0) ||
      (getters.readinessLabels && getters.readinessLabels.length > 0)
  },
  averageRevenuePerFTE (state) {
    return state.averageRevenuePerFTE
  },
  syncableEcosystems (state) {
    return state.ecosystemSyncConfigurations
  },
  isTeamleaderConfigured (state) {
    if (state.keys && state.keys.teamleader && state.keys.teamleader.api_key && state.keys.teamleader.group_id) {
      return true
    }

    return false
  },

  homepageSections () {
    return ['messages', 'directory', 'buzz', 'news', 'files']
  },
  hexColours (state) {
    return state.hexColours
  },
  legendMapping (state) {
    return state.legendMapping
  },
  aspectTaxonomyEnabled (state) {
    return state.taxonomyAspectsEnabled == true
  },
  readinessTaxonomyEnabled (state) {
    return state.taxonomyReadinessEnabled == true
  },
  businessAspectTaxonomyEnabled (state) {
    return state.taxonomyBusinessAspectsEnabled == true
  },
  taxonomyProcessStepsEnabled (state) {
    return state.taxonomyProcessStepsEnabled == true
  },
  isEpoEnabled (state) {
    return ['full', 'advanced', 'premium'].includes(state.data_package)
  },
  packageType (state) {
    return state.package_type
  },
  dataPackage (state) {
    return state.data_package
  },
  forIntelligenceAndOperations (state) {
    return state.forIntelligenceAndOperations
  },
  layeredPackages (state) {
    return state.layeredPackages
  },
  isStakeholderMappingOnly (state) {
    if (!state.forIntelligenceAndOperations) {
      return false
    }

    return Object.values(state.layeredPackages).every((x) => x === false)
  },
  isEcosystemOperations (state) {
    if (!state.forIntelligenceAndOperations) {
      return this.$store.getters.publisher
    }

    return Object.values(state.layeredPackages).find(layeredPackage => layeredPackage === 'operations')
  },
  isExplorationOnly (state) {
    return state.exploration_add_on_enabled == true &&
      state.monitoring_add_on_enabled == false &&
      state.sensing_add_on_enabled == false &&
      state.scores_add_on_enabled == false
  },
  hasAccessToDictionaries (state) {
    return state.exploration_add_on_enabled == true ||
      state.monitoring_add_on_enabled == true
  },
  hasAccessToBuzz (state) {
    return state.package_type == 'standard' && state.sensing_add_on_enabled == true
  },
  hasAccessToScores (state) {
    return state.package_type == 'standard' && state.scores_add_on_enabled == true
  },
  hasAccessToCustomScores (state) {
    return state.package_type == 'standard' && state.scores_add_on_enabled == true
  },
  hasAccessToPublisher (state) {
    return state.publisher_add_on_enabled == true ||
      state.package_type == 'standard'
  },
  hasAccessToAgents (state) {
    return ['standard'].includes(state.package_type) &&
      state.agents_add_on_enabled == true &&
      ['premium', 'full', 'advanced'].includes(state.data_package)
  },
  hasAccessToSensing (state) {
    return state.package_type == 'standard' && state.sensing_add_on_enabled == true
  },
  hasAccessToExploration (state) {
    return state.exploration_add_on_enabled == true
  },
  hasAccessToMonitoring (state) {
    return state.monitoring_add_on_enabled == true
  },
  hasAccessToActorScouting (state) {
    return state.enableActorScouting === true
  },
  hasAccessToEcosystemMemberPackages (state) {
    return state.ecosystem_member_packages_add_on_enabled == true
  },
  hasStripeKeys (state) {
    return state.stripe && !!state.stripe.stripe_key
  },
  hasAccessToExplorationOrSensing (state, getters) {
    return getters.hasAccessToExploration || getters.hasAccessToSensing
  },
  hasAccessToExplorationOrMonitoring (state, getters) {
    return getters.hasAccessToExploration || getters.hasAccessToMonitoring
  },
  hasAccessToSharing (state) {
    return state.package_type == 'standard' && state.sharing_add_on_enabled == true
  },
  hasAccessToPortfolioSharing (state) {
    return state.sharing_add_on_enabled == true || state.spotting_areas_add_on_enabled == true
  },
  hasAccessToKnowledgeBase (state) {
    return true
  },
  canViewAllKnowledgeBaseContentTypes (state) {
    return state.knowledgeBaseViewType !== 'upload_only'
  },
  canUserUploadContent (state, getters) {
    if (!getters.isLoggedIn) {
      return false
    }

    if (getters.isActor) {
      return !!state.canUploadContentToKnowledgeBase
    }

    return true
  },
  hasAccessToRelevancy (state, getters) {
    return (getters.hasAccessToMonitoring || getters.hasAccessToExploration) && getters.hasAtLeastOneConceptSearch
  },
  hasAccessToRelevancyUi (state, getters) {
    return getters.hasAccessToRelevancy && getters.isMember
  },
  isScoresEnabled (state) {
    return ['standard', 'pro', 'agency'].includes(state.package_type) && !!state.hasScores
  },
  visitorSiteTemplate (state) {
    if (!state.permissions || !state.permissions.visitor) {
      return false
    }

    return state.permissions.visitor.siteTemplate || SiteTemplate.TRADITIONAL
  },
  ecosystemMemberSiteTemplate (state) {
    if (!state.publisher) {
      return false
    }

    return state.siteTemplate || state.publisher.siteTemplate || SiteTemplate.TRADITIONAL
  },
  publicStripeKey (state) {
    return state.stripe && state.stripe.stripe_key
  },
  activeSiteTemplate (state, getters) {
    if (!getters.isLoggedIn) {
      return getters.visitorSiteTemplate
    }

    if (getters.isActor) {
      return getters.ecosystemMemberSiteTemplate
    }
  },
  displayCurrency (state) {
    if (!state.displayCurrency) {
      return 'eur'
    }

    return state.displayCurrency
  },
  activeMonitoringContentMode (state, getters) {
    const visitorMode = objectPath.has(state, 'permissions.visitor') ? objectPath.get(state, 'permissions.visitor.monitoringContentMode', 'all') : 'curated'
    const actorMode = objectPath.get(state, 'publisher.monitoringContentMode', 'all')
    const teamMemberMode = objectPath.has(state, 'permissions.member') ? objectPath.get(state, 'permissions.member.monitoringContentMode', 'all') : actorMode
    const portfolioMemberMode = objectPath.has(state, 'permissions.portfolio_member') ? objectPath.get(state, 'permissions.portfolio_member.monitoringContentMode', 'all') : teamMemberMode

    if (!getters.isLoggedIn) {
      return visitorMode
    }

    if (getters.isActor) {
      return actorMode
    }

    if (getters.isTeamMember) {
      return teamMemberMode
    }

    if (getters.isPortfolioMember) {
      return portfolioMemberMode
    }
  },
  chatbotCustomization (state) {
    const ecosystemName = state.name.toLowerCase()

    if (!customChatbotConfigurations[ecosystemName]) {
      return null
    }

    const configuration = customChatbotConfigurations[ecosystemName]
    let logo = '/images/minerva-logo.svg'

    if (state.favicon_url) {
      logo = state.favicon_url
    }

    configuration['assistantLogo'] = logo

    return configuration
  }
}

export const actions = {
  [ACTION_TYPES.REFRESH_CONFIG] ({ commit }) {
    fetchConfig()
      .then(response => {
        commit(MUTATION_TYPES.CONFIG_SET, response)
      })
      .catch(err => {
        console.log(err)
      })
  },
}

export const mutations = {
  [MUTATION_TYPES.CONFIG_SET] (state, newConfig) {
    // Set defaults
    newConfig = Object.assign({}, possibleFutureConfig, newConfig)

    // Apply
    Object.assign(window.config, newConfig)
    Object.assign(state, newConfig)
  },
}

export const state = {}

// Immediately set config, app cannot run without config.
mutations[MUTATION_TYPES.CONFIG_SET](state, window.config)

export default {
  state,
  getters,
  mutations,
  actions,
}
