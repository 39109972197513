<template>
  <div
      class="community-members__container has-parent-page"
      ref="scrollable"
  >
    <Container style="margin-top: 2rem;">
      <div class="actor-contributors__container">

        <DsInput
            placeholder="Search a member"
            is-simplified
            style="width: 300px; margin-bottom: 1.5rem;"
            @keydown.enter="searchMember"
            @update:modelValue="searchMemberDebounced"
            v-model="memberQuery"
        />

        <template v-if="loadingMembers">
          <Loading/>
        </template>

        <template v-else>
          <CommunityMemberCard
              v-for="(member, index) in members"
              :member="member"
              :key="'member' + index"
              :show-organization-link="true"
          />
        </template>
      </div>
    </Container>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { UserBaseInfoData } from '@/Domain/User/Types/types'
import { fetchMembers, removeMember } from '@/api/communities'
import Container from '@/components/Container/Container.vue'
import { Conversations } from '@/api/conversations'
import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '@/store/modules/conversations'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '@/store/modules/ui'
import CommunityMixin from '@/util/CommunityMixin'
import SimpleSocialItem from '@/components/Card/SimpleSocialItem.vue'
import UserSocialButtons from '@/Domain/User/Components/UserSocialButtons.vue'
import { Community } from '@/Domain/Community/types'
import Loading from '@/components/Dashboard/ConceptMap/Loading.vue'
import MODAL_IDS from '@/constants/modal-ids'
import DsInput from '@/components/Form/DsInput.vue'
import debounce from 'lodash/debounce'
import CommunityMemberCard from '@/components/Communities/CommunityMemberCard.vue'

declare interface BaseComponentData {
  originalMembers: UserBaseInfoData[],
  members: UserBaseInfoData[],
  loadingMembers: boolean,
  memberQuery: string,
  loading: boolean,
  searchResults: UserBaseInfoData[]
}

export default defineComponent({
  name: "CommunityMembers",
  components: {
    CommunityMemberCard,
    DsInput,
    Loading,
    UserSocialButtons,
    SimpleSocialItem,
    Container
  },
  mixins: [CommunityMixin],
  data(): BaseComponentData {
    return {
      originalMembers: [],
      members: [],
      loadingMembers: false,
      memberQuery: '',
      searchResults: [],
      loading: false,
    }
  },
  computed: {
    loggedInUserId(): number {
      return this.$store.getters.userId
    },
    communityId(): number {
      return Number(this.$route.params.communityId)
    },
    community(): Community | null {
      return this.$store.state.communities.detail.data
    },
  },
  mounted() {
    this.fetchCommunityMembers()

    this.$bus.on('communityMemberDeleteConfirmation', this.removeMember)
  },
  methods: {
    openUserSidePanel(member) {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        component: 'user-information-side-panel',
        metaData: {
          userId: member.id,
          allowDelete: false,
        },
      })
    },
    searchMemberDebounced: debounce(function () {
      this.searchMember()
    }, 100),
    searchMember() {
      this.loadingMembers = true;

      this.memberQuery = this.memberQuery.trim().toLowerCase();

      if (!this.memberQuery) {
        this.members = this.originalMembers
        this.loadingMembers = false;

        return;
      }

      this.members = this.originalMembers
          .filter((member) => {
            return member.name.toLowerCase().includes(this.memberQuery) || member.email.toLowerCase().includes(this.memberQuery)
          })

      this.loadingMembers = false;
    },
    promptRemoveMemberConfirmationModal(member: UserBaseInfoData) {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        body: 'Do you want to remove this user from the community?',
        communityName: this.community.name,
        memberName: member.name,
        memberId: member.id,
        modalContextType: 'community-member',
      })

      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    removeMember(modalContext: any) {
      removeMember(this.communityId, modalContext.memberId)
          .then(() => {
            this.fetchCommunityMembers()
          })
          .catch(error => {
            console.error(error)
          })
    },
    fetchCommunityMembers() {
      this.loadingMembers = true

      fetchMembers(this.communityId)
          .then((response) => {
            this.members = response
            this.originalMembers = response
            this.loadingMembers = false
          })
          .catch(error => {
            console.error(error)
            this.loadingMembers = false
          })
    },
    startConversation($event, member) {
      Conversations
          .post({ recipient_id: member.id })
          .then((conversation) => {
            this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
            this.$router.push('/profile/conversations/' + conversation.id)
                .then(() => {
                  this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
                    component: 'user-information-side-panel',
                    metaData: {
                      userId: member.id,
                      allowDelete: false,
                    },
                  })
                })
          })
    }
  },
  watch: {
    memberQuery() {
      if (!this.memberQuery) {
        this.members = this.originalMembers
      }
    }
  }
})
</script>

<style scoped lang="scss">

@import "../../scss/variables.scss";

.community-members__members-container {
  margin-top: 2rem;
}

.community-members__container {
  height: 100%;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

// other height is necessary for page sections on detail pages
.has-parent-page {
  .filters-sticky {
    top: #{$top-bar-height};
    height: calc(100vh - #{$top-bar-height} - #{$second-top-bar-height});
  }
}

.actor-contributors__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 20px 20px;

  p {
    font-size: 14px;
  }
}
</style>
