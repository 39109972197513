// Router
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// Analytics
import { trackHeapEvent } from '@/util/analytics'
import { logUserActivity } from '@/util/user-analytics'
// Store
import $store from '../store/index'
// Pages
// import Home from '../components/Dashboard/Home.vue'
// import HomeView from '../pages/HomeView.vue'
import Actor from '../pages/Actor.vue'
import FileDetail from '../pages/FileDetail.vue'
import ActorChanges from '../pages/ActorChanges.vue'
import ActorDetailVersionChooser from '../pages/ActorDetail/ActorDetailVersionChooser.vue'
import ActorScores from '../pages/ActorScores.vue'
import AddActor from '../pages/AddActor.vue'
import AnnouncementDetailSimplifiedNew from '../pages/Simplified/AnnouncementDetailSimplifiedNew.vue'
import ChallengeDetail from '../pages/Simplified/ChallengeDetail.vue'
import ChangePassword from '../pages/ChangePassword.vue'
import CommunityDetail from '../pages/CommunityDetail/CommunityDetail.vue'
import Dashboards from '../pages/Dashboards.vue'
import Datalab from '../pages/Datalab.vue'
import Explore from '../pages/Explore.vue'
import ProductGallery from '../pages/Products.vue'
import ActorsSimplified from '../pages/Simplified/ActorsSimplified.vue'
import IntelligenceSimplified from '../pages/Simplified/IntelligenceSimplified.vue'
import IntelligenceDetail from '../pages/Simplified/IntelligenceDetail.vue'
import ForgotPassword from '../pages/ForgotPassword.vue'
import Info from '../pages/Info.vue'
import KitchenSink from '../pages/KitchenSink.vue'
import Notifications from '../pages/Notifications.vue'
import AnnouncementsNew from '../pages/AnnouncementsNew.vue'
import Profile from '../pages/Profile.vue'
import Portfolios from '../pages/Portfolios.vue'
import Restricted from '../pages/Restricted.vue'
import Offline from '../pages/Offline.vue'
import Settings from '../pages/Settings.vue'
import SignIn from '../pages/SignIn.vue'
import SignUp from '../pages/SignUp.vue'
import SpottingAreaDetail from '../pages/Simplified/SpottingAreaDetail.vue'
import GdprTerms from '../pages/GdprTerms.vue'
import ConceptMap from '../components/Dashboard/ConceptMap.vue'
import InstantMonitoring from '../components/Dashboard/InstantMonitoring/InstantMonitoring.vue'
import SortablePortfolioDetail from '../pages/SortablePortfolioDetail.vue'
import Challenges from '../pages/Challenges.vue'
import EventsNew from '../pages/EventsNew.vue'

// API
import { Portfolios as fetchPortfolio } from '../api/portfolios'
import { SiteTemplate } from '../store/modules/config'
import { ACTION_TYPES as USER_ACTION_TYPES } from '../store/modules/user.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui.js'
import Communities from '../pages/Communities.vue'
import SpottingAreas from '../pages/SpottingAreas.vue'
import ActorScouting from '../components/Dashboard/ActorScouting.vue'
import MODAL_IDS from '@/constants/modal-ids'

function mustLogin(to, from, next) {
  if ($store.getters.hasAccessToPublisher && !!$store.state.config.allow_anonymous && !window.oauth_request) {
    if (!$store.getters.isLoggedIn) {
      $store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
    }

    next()
  } else {
    $store.getters.isLoggedIn ? next() : next('/login')
  }
}

function hasAccessToCommunities(to, from, next) {
  if ($store.getters.hasAccessToCommunities) {
    next()
  } else {
    next('/')
  }
}

function defaultSettingsPage(to, form, next) {
  $store.getters.isOwner ? next('/settings/ecosystem') : next('/settings/users')
}

// Take a portfolio where the user has explore or contribute access to and set it as the active portfolio
function setExplorePortfolio() {
  const listOfPortfolios = []

  if ($store.state.managePortfolio.activePortfolio.id === undefined) {
    // Fetch all the portfolios that the user can contribute to
    if ($store.getters.userContributionPortfolios && $store.getters.userContributionPortfolios.length > 0) {
      $store.getters.userContributionPortfolios.forEach(portfolio => {
        if (!listOfPortfolios.includes(portfolio)) {
          listOfPortfolios.push(portfolio)
        }
      })
    }

    // Fetch all the portfolios that the user can explore
    if ($store.getters.userExplorePortfolios && $store.getters.userExplorePortfolios.length > 0) {
      $store.getters.userExplorePortfolios.forEach(portfolio => {
        if (!listOfPortfolios.includes(portfolio)) {
          listOfPortfolios.push(portfolio)
        }
      })
    }

    const localStoragePortfolio = window.localStorage.getItem('portfolio')

    if (localStoragePortfolio && localStoragePortfolio !== 'null') {
      const portfolio = localStoragePortfolio.replaceAll('"', '')

      fetchPortfolio.get(portfolio).then(portfolio => {
        $store.commit('FILTERS/SET_PORTFOLIO', portfolio.id)
        $store.commit('PORTFOLIOS/SET_ACTIVE_PORTFOLIO', portfolio)
      })
    } else {
      if (listOfPortfolios.length > 0) {
        // If there are portfolios that the user can either contribute or explore, the first portfolio of the created list will be fetched and set
        fetchPortfolio.get(listOfPortfolios[0]).then(portfolio => {
          $store.commit('FILTERS/SET_PORTFOLIO', portfolio.id)
          $store.commit('PORTFOLIOS/SET_ACTIVE_PORTFOLIO', portfolio)
        })
      }
    }
  }
}

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/dashboards/home' },
  { path: '/actors', name: 'explore', component: Explore },
  { path: '/actors-simplified', name: 'actors-simplified', component: ActorsSimplified },
  { path: '/communities', name: 'communities', component: Communities, beforeEnter: hasAccessToCommunities },
  { path: '/communities/:communityId/:section?/:id?', name: 'community-detail', component: CommunityDetail, beforeEnter: hasAccessToCommunities },
  { path: '/products-simplified', name: 'products-simplified', component: ActorsSimplified },
  { path: '/intelligence-simplified', name: 'intelligence-simplified', component: IntelligenceSimplified },
  { path: '/intelligence-simplified/:id', name: 'intelligence-detail', component: IntelligenceDetail },
  { path: '/announcements-simplified', name: 'announcements-simplified', component: AnnouncementsNew },
  { path: '/events-simplified', name: 'events-simplified', component: EventsNew },
  { path: '/events', name: 'events', component: EventsNew },
  { path: '/events/:id', name: 'events-detail', component: AnnouncementDetailSimplifiedNew },
  { path: '/product-gallery', name: 'product-gallery', component: ProductGallery },
  { path: '/actors/create', name: 'addActor', component: AddActor, beforeEnter: mustLogin },
  { path: '/files/:fileId/:viewType?/:section?', name: 'fileDetail', component: FileDetail, beforeEnter: mustLogin },
  { path: '/admin', redirect: '/datalab' },
  { path: '/analytics', redirect: '/dashboards/analytics' },
  { path: '/dashboards', redirect: '/dashboards/analytics' },
  { path: '/dashboards/instant-monitoring/:id?', name: 'InstantMonitoring', component: InstantMonitoring },
  { path: '/dashboards/actor-scouting', name: '/dashboards/actor-scouting', component: ActorScouting },
  { path: '/dashboards/concept-map', redirect: '/dashboards/explore-content' },
  { path: '/dashboards/concept-map/:id', redirect: '/dashboards/concept-map/:id/content' },
  { path: '/dashboards/concept-map/:id/:tab?/:sub?', name: 'ConceptMap', component: ConceptMap },
  { path: '/dashboards/:panel', name: 'dashboards', component: Dashboards },
  {
    path: '/actors/:id',
    component: Actor,

    children: [
      { path: '', name: 'actor-detail', component: ActorDetailVersionChooser },
      { path: 'changes', component: ActorChanges },
      { path: 'scores', component: ActorScores },
    ],
  },
  { path: '/spotting-areas', name: 'spottingAreas', component: SpottingAreas },
  { path: '/spotting-areas/:spottingAreaId/:section?/:id?', name: 'spotting-area-detail', component: SpottingAreaDetail },
  { path: '/settings', redirect: '/settings/ecosystem', beforeEnter: defaultSettingsPage },
  { path: '/settings/:panel', name: 'settings', component: Settings, beforeEnter: mustLogin },
  { path: '/datalab', redirect: '/datalab/completion' },
  { path: '/datalab/:panel', name: 'datalab', component: Datalab, beforeEnter: mustLogin },
  { path: '/forgot-password', name: 'forgot-password', component: ForgotPassword },
  { path: '/login', name: 'login', component: SignIn },
  /*{
    path: '/dashboards/home',
    name: 'home',
    component: HomeView,
    beforeEnter: mustLogin,
  },*/
  {
    path: '/login',
    name: 'login',
    component: SignIn,
  },
  { path: '/notifications', name: 'notifications', component: Notifications },
  { path: '/notifications/:id', name: 'notifications-detail', component: Notifications },
  { path: '/announcements', name: 'announcements', component: AnnouncementsNew },
  { path: '/events-simplified', name: 'events-simplified', component: EventsNew },
  { path: '/events', name: 'events', component: EventsNew },
  { path: '/events/:id', name: 'events-detail', component: AnnouncementDetailSimplifiedNew },
  { path: '/challenges', name: 'challenges', component: Challenges },
  { path: '/challenges/:id', name: 'challenges-detail', component: ChallengeDetail },
  { path: '/password/reset/:token', name: 'change-password', component: ChangePassword },
  { path: '/portfolios', name: 'portfolios', component: Portfolios },
  { path: '/portfolios/:id', name: 'portfolio-detail', component: SortablePortfolioDetail, beforeEnter: mustLogin },
  { path: '/profile', redirect: '/profile/update' },
  { path: '/profile-old', redirect: '/profile-old/update' },
  { path: '/profile/:panel/:id?', name: 'profile', component: Profile, beforeEnter: mustLogin },
  { path: '/profile-old/:panel/:id?', name: 'profile-old', component: Profile, beforeEnter: mustLogin },
  { path: '/register', name: 'sign-up', component: SignUp },
  { path: '/register/confirm/:token', redirect: '/register' },
  { path: '/restricted', name: 'restricted', component: Restricted, beforeEnter: mustLogin },
  { path: '/offline', name: 'offline', component: Offline },
  { path: '/datascouts-terms-and-conditions', name: 'gdpr-terms', component: GdprTerms, beforeEnter: mustLogin },
  { path: '/settings', redirect: '/settings/ecosystem', beforeEnter: defaultSettingsPage },
  { path: '/settings/:panel', name: 'settings', component: Settings, beforeEnter: mustLogin },
  { path: '/spotting-areas', name: 'spotting-areas', component: SpottingAreas },
  { path: '/spotting-areas/:spottingAreaId/:section?/:id?', name: 'spotting-area-detail', component: SpottingAreaDetail },
  {
    path: '/announcements/:id',
    name: 'announcements-detail',
    component: AnnouncementDetailSimplifiedNew,
  },
  { path: '/info', name: 'info', component: Info },
]

if (import.meta.env.DEV) {
  routes.push({
    path: '/kitchen-sink',
    beforeEnter: mustLogin,
    component: KitchenSink,
  })
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'navigation__link--active',
})

router.beforeEach(async (to, from, next) => {
  if ($store.state.ui.sidePanel.isVisible && to.path !== from.path) {
    $store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
  }

  if ($store.getters.isPortfolioMember && (to.name !== 'explore' && to.name !== 'addActor')) {
    // If the user is in the directory, the user can remove the portfolio
    // Since the state is reset when the browser is refreshed, this will execute the function that fetches a portfolio
    setExplorePortfolio()
  }

  if (document.body.clientWidth < 768 && ['explore', 'settings', 'portfolios'].includes(to.name) && to.path !== $store.getters.home) {
    return next('/')
  }

  // Send users that did not accept terms yet to the datascouts-terms-and-conditions page
  if ($store.getters.isLoggedIn && !$store.getters.hasAcceptedTerms && (to.name != 'gdpr-terms' && to.path != '/profile/update') && $store.getters.isGdprDue) {
    return next('/datascouts-terms-and-conditions')
  }

  // If users go to the register (signup) and they're already signed in or canRegister is false, return to '/'
  if (to.name == 'sign-up' && $store.getters.isLoggedIn) {
    return next('/')
  }

  // If the user wants to register on an ecosystem you can't register on, without any invitation, you're not getting in
  var isCompletingRegistration = (to.redirectedFrom && to.redirectedFrom.path && to.redirectedFrom.path.includes('register/confirm/')) || to.fullPath.includes('/register?email=')

  if (to.name == 'sign-up' && !$store.getters.canRegister && !to.query.invitation && !isCompletingRegistration) {
    return next('/login')
  }

  if ($store.getters.hasAccessToActorScouting) {
    if (to.name && (to.path.startsWith('/dashboards') && (to.path === '/dashboards/knowledge-base' || to.path === '/dashboards/explore-content'))) {
      return next('/')
    }
  }

  if (!$store.getters.isLoggedIn && $store.getters.isFoodleap) {
    if (to.path === '/communities' || to.path === '/actors-simplified' || to.path === '/products-simplified') {
      return next('/')
    }
  }

  // For simplified templates, visitors and ecosystem member have access to only a few specific pages, reroute the user to the homepage if they're routed to dashboards
  if (to.name &&
      (
          (to.name.startsWith('dashboards/') && (!to.name.startsWith('dashboards/home') && !to.name.startsWith('dashboards/foodvalley-about') && !to.name.startsWith('dashboards/foodvalley-contact') && !to.name.startsWith('dashboards/knowledge-base') && !to.name.startsWith('dashboards/explore-content')) && !to.name.startsWith('static')) ||
          to.name === 'explore'
      ) &&
      [SiteTemplate.SIMPLIFIED, SiteTemplate.NEW_SIMPLIFIED].includes($store.getters.activeSiteTemplate) &&
      (!$store.getters.isLoggedIn || $store.getters.isActor)
  ) {
    return next('/')
  }

  if (to.path !== from.path) {
    try {
      trackHeapEvent('pageExit', { from: from.path, to: to.path })
    } catch (err) {
    }
  }

  if (to.name != 'gdpr-terms' && to.path !== '/dashboards/home' && $store.getters.isActor && $store.getters.hasAccessToEcosystemMemberPackages) {
    // Fetch package info and redirect to homepage if no active subscription
    if (!$store.state.user.isFetchingPackageSubscription && !$store.state.user.hasFetchedPackageSubscription) {
      await $store.dispatch(USER_ACTION_TYPES.FETCH_PACKAGE_SUBSCRIPTION)
    }

    // In case there is a promise, but fetching the info has not succeeded yet
    if ($store.state.user.fetchPackageSubscriptionPromise && !$store.state.user.hasFetchedPackageSubscription) {
      await $store.state.user.fetchPackageSubscriptionPromise
    }

    if (!$store.state.user.profile.subscriptionInfo || !$store.state.user.profile.subscriptionInfo.package) {
      return next('/dashboards/home')
    }
  }

  next()
})

router.afterEach((to, from) => {
  if (window.refreshBeforeRedirect) {
    // This is a work around to fetch all the necessary data that comes with a new load
    window.refreshBeforeRedirect = false
    window.location.reload()
  }

  // For simplified templates, visitors and ecosystem member have access to only a few specific pages, reroute the user to the homepage if they're routed to dashboards
  if (to.name &&
      ((to.name.startsWith('dashboards/') && !to.name.startsWith('dashboards/home') && !to.name.startsWith('dashboards/foodvalley-about') && !to.name.startsWith('dashboards/foodvalley-contact') && !to.name.startsWith('static')) || to.name === 'explore') &&
      [SiteTemplate.SIMPLIFIED, SiteTemplate.NEW_SIMPLIFIED].includes($store.getters.activeSiteTemplate) &&
      (!$store.getters.isLoggedIn || $store.getters.isActor)
  ) {
    router.push('/')
  }

  if (to.path !== from.path) {
    // Don't cause extra load by making instant API calls when switching routes
    setTimeout(() => {
          logUserActivity(to.path, to.name, to.params.id)
        },
        5000)
  }
})

export default router
