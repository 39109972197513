<template>
  <template v-if="community?.id && Number(community.id) === Number(communityId)">
    <!-- move this out of the community detail div, otherwise scrolling wont work, this component needs to be reworked a bit so that sub navigation sections are used in the most vanilla way they can be, instead of being wrapped in divs -->
    <template v-if="displayAnnouncements">
      <AnnouncementsNew :allow-create-announcement="canCreateAnnouncement"/>
    </template>

    <template v-else-if="displayEvents">
      <EventsNew :allow-create-announcement="canCreateAnnouncement"/>
    </template>

    <div
        class="page-community-detail scrollable communities"
        :class="{'apply-community-color': subNavSection !== 'members' || !$route.params.id}"
    >
      <!-- don't apply community color on actor detail page -->
      <BasicHero
          :title="community.name"
          :subtitle="community.description"
          v-if="!subNavSection"
      />

      <!-- START OF COMMUNITY OVERVIEW PAGE -->
      <div v-if="subNavSection === ''">
        <div class="container communities-container" style="margin-bottom: 20px;">

          <div class="no-claim-badge" v-if="!hasCommunityAccessBasedOnAccessLevel(community)">
            <icon name="warning" size="14" fill="white"></icon>
            {{
              $t('communities_warning_claim_actor')
            }}
            <span class="no-claim-badge-button" @click="showGlobalSearch">{{
                $t('communities_warning_claim_actor_cta')
              }}</span>
          </div>

          <div class="row">

            <div class="col-xs-12 col-sm-12 col-md-6" v-if="manager">
              <community-detail-manager-card :manager="manager">
                <h4 class="h4">{{ manager.name }}</h4>
                <div class="community-detail__manager-bio">{{ manager.bio }}</div>
              </community-detail-manager-card>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <div style="display: flex">
                <router-link
                    v-if="doesUserHaveFullAccessToCommunity"
                    :to="`/communities/${community.id}/members`"
                    class="communities-members-link"
                    :class="{'has-action-buttons': isMember}"
                    style="text-decoration: none"
                >
                  <div class="communities-title">
                    {{
                      $t('community_latest_members', { community: community.name })
                    }}
                  </div>
                </router-link>
                <div class="communities-title" v-else>
                  {{
                    $t('community_latest_members', { community: community.name })
                  }}
                </div>

                <div class="communities-title-action-item" v-if="canUserManageMembers">
                  <ds-button
                      icon="plus-big"
                      variant="minimal"
                      @click="displayAddMemberModal"
                  />
                </div>

                <div class="communities-title-action-item">
                  <ds-button
                      icon="chart-pie"
                      v-if="isMember"
                      variant="minimal"
                      @click="navigateToDynamicDashboard"
                  />
                </div>
              </div>

              <div
                  class="community-detail__members" id="introjs-communities-detailpage"
              >
                <div v-if="latestMembers.loading" style="margin: auto;">
                  <loading/>
                  <br>
                </div>

                <community-detail-members-card
                    v-else-if="latestMembers && latestMembers.data.length > 0"
                    :members="latestMembers.data"
                />
                <div style="margin: auto;" v-else>
                  {{ $t('community_members_empty_text') }}
                </div>
              </div>
            </div>
          </div>

          <CommunityDetailLastCommunityItems
              v-if="areAnnouncementsEnabledForCommunity && community.id"
              :community="community"
          />

        </div>
      </div>
      <!-- END OF DETAIL OVERVIEW PAGE -->

      <template v-if="subNavSection === 'members'">
        <CommunityMembers/>
      </template>

      <div v-if="subNavSection === 'actors'" :class="$route.params.id ? '' : 'scrollable'">
        <loading v-if="loadingCommunity"/>
        <!-- TODO: this should either be a generic access denied component, but actually, should not be navigable in the first place + it's either showing the denied component OR the simplified page, not both at the same time -->
        <!--<community-detail-members-access-denied
          v-if="manager && !hasAccessToMembers"
          :manager="manager"
        />-->
        <ActorsSimplified
            v-if="!$route.params.id && doesUserHaveFullAccessToCommunity"
            :card-class="'community-simplified-image-card'"
        />
        <SimplifiedActorDetail v-else-if="doesUserHaveFullAccessToCommunity"/>
      </div>

      <template v-if="subNavSection === 'announcements' && $route.params.id && $route.params.communityId">
        <announcement-detail-simplified-new/>
      </template>

      <template v-if="subNavSection === 'events' && $route.params.id && $route.params.communityId">
        <announcement-detail-simplified-new/>
      </template>

      <div v-if="subNavSection === 'knowledge-base'" class="scrollable" style="min-height: 70vh">
        <knowledge-base-simplified :enable-create="$store.getters.canUserUploadContent"/>
      </div>
      <div v-if="subNavSection === 'challenges' && !$route.params.id" class="scrollable" style="min-height: 70vh">
        <Challenges/>
      </div>
      <div
          v-if="subNavSection === 'challenges' && $route.params.id && $route.params.communityId" class="scrollable"
          style="min-height: 70vh"
      >
        <ChallengeDetail/>
      </div>

      <SimplifiedFooter v-if="subNavSection === ''"/>
    </div>
  </template>
  <template v-else>
    <div class="page-community-detail scrollable communities apply-community-color" style="padding-top: 75px;">
      <Loading/>
    </div>
  </template>
</template>

<script lang="ts">
import BasicHero from '../../components/BasicHero/BasicHero.vue'
import ActorsSimplified from '../Simplified/ActorsSimplified.vue'
import AnnouncementsNew from '../AnnouncementsNew.vue'
import Avatar from '../../components/Avatar/Avatar.vue'
import Loading from '../../components/Dashboard/ConceptMap/Loading.vue'
import SimplifiedImageCard from '../../components/Connection/SimplifiedImageCard.vue'
import CommunityDetailMembersCard from '../../components/Communities/CommunityDetailMembersCard.vue'
import KnowledgeBaseSimplified from '../../components/Dashboard/KnowledgeBaseSimplified.vue'
import TranslationsMixin from '../../util/TranslationsMixin.js'
import { getCardsPerCarouselPageBasedOnWindowWidth } from '../../util/helpers.js'

import CommunityDetailManagerCard from '../../components/Communities/CommunityDetailManagerCard.vue'
import CommunitiesCard from '../../components/Communities/CommunitiesCard.vue'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
import { ACTION_TYPES as COMMUNITY_ACTION_TYPES } from '../../store/modules/communities.js'
import { MUTATION_TYPES as NOTIFICATION_MUTATION_TYPES } from '../../store/modules/notifications.js'
import SimplifiedActorDetail from '../ActorDetail/SimplifiedActorDetail.vue'
import NewCard from '../../components/NewCard/NewCard.vue'
import CommunityDetailMembersAccessDenied from '../../components/Communities/CommunityDetailMembersAccessDenied.vue'
import AnnouncementDetailSimplifiedNew from '../Simplified/AnnouncementDetailSimplifiedNew.vue'
import SimplifiedAnnouncementPreview from '../../components/Simplified/SimplifiedAnnouncementPreview.vue'
import SimplifiedFooter from '../../components/SimplifiedFooter/SimplifiedFooter.vue'
import EventCard from '../../components/Simplified/EventCard.vue'
import { mix } from 'polished'
import IntroJsMixin from '../../util/IntroJsMixin.js'
import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import MODAL_IDS from '../../constants/modal-ids.js'
import { fetchLatestMembers } from '@/api/communities.js'
import EventsNew from '../EventsNew.vue'
import { defineComponent } from 'vue'
import Challenges from '../Challenges.vue'
import ChallengeDetail from '@/pages/Simplified/ChallengeDetail.vue'
import CommunityMixin from '@/util/CommunityMixin'
import CommunityMembers from '@/pages/CommunityMembers.vue'
import ChallengeCard from '@/components/Simplified/ChallengeCard.vue'
import CommunityDetailLastCommunityItems from '@/components/Communities/CommunityDetailLastCommunityItems.vue'
import { CommunityDetail } from '@/Domain/Community/types'
import { UserBaseInfoData } from '@/Domain/User/Types/types'

export default defineComponent({
  components: {
    CommunityDetailLastCommunityItems,
    ChallengeCard,
    CommunityMembers,
    ChallengeDetail,
    Challenges, EventsNew,
    SimplifiedFooter,
    CommunityDetailMembersAccessDenied,
    NewCard,
    CommunitiesCard,
    CommunityDetailManagerCard,
    CommunityDetailMembersCard,
    ActorsSimplified,
    BasicHero,
    AnnouncementsNew,
    Avatar,
    Loading,
    SimplifiedImageCard,
    SimplifiedActorDetail,
    AnnouncementDetailSimplifiedNew,
    EventCard,
    SimplifiedAnnouncementPreview,
    KnowledgeBaseSimplified,
  },
  data() {
    return {
      recentAnnouncements: {
        data: [],
        isLoading: false,
      },
      upcomingEvents: {
        data: [],
        isLoading: false,
      },
      latestMembers: {
        data: [],
        isLoading: false,
      },
      latestChallenges: [],
      cardsPerCarouselPage: getCardsPerCarouselPageBasedOnWindowWidth(window.innerWidth),
    }
  },
  computed: {
    areAnnouncementsEnabledForCommunity(): boolean {
      return this.areMessageBoardsEnabled && this.hasAccessToAnnouncementsAndEventsForCommunities
    },
    displayEvents(): boolean {
      return this.areMessageBoardsEnabled
          && this.hasAccessToAnnouncementsAndEventsForCommunities
          && this.subNavSection === 'events'
          && !this.$route.params.id
    },
    displayAnnouncements(): boolean {
      return this.areAnnouncementsEnabledForCommunity
          && this.subNavSection === 'announcements'
          && !this.$route.params.id
    },
    loadingCommunity(): boolean {
      return this.$store.state.communities.detail.loading
    },
    community(): CommunityDetail | null {
      return this.$store.state.communities.detail.data
    },
    manager(): UserBaseInfoData | null {
      return this.community?.communityManager
    },
    isLoggedIn(): boolean {
      return this.$store.getters.isLoggedIn
    },
    isMember(): boolean {
      return this.$store.getters.isMember
    },
    hasAccessToMembers(): boolean {
      if (!this.community) {
        return false
      }

      if (this.isMember) {
        return true
      }

      return this.joinedCommunities.filter((c) => c.id === this.community?.id).length > 0
    },
    isLoadingChallenges() {
      return this.$store.state.challenges.listData.loading
    },
    areMessageBoardsEnabled(): boolean {
      return this.$store.getters.areMessageBoardsEnabled
    },
    hasAccessToAnnouncementsAndEventsForCommunities(): boolean {
      return this.$store.getters.hasAccessToAnnouncementsAndEventsForCommunities
    },
    communityId(): number {
      return Number(this.$route.params.communityId)
    },
    subNavSection(): string {
      return this.$route.params.section
    },
    config(): any {
      return this.$store.state.config
    },
  },
  methods: {
    showGlobalSearch() {
      this.$bus.emit('showGlobalSearch', { query: '' })
    },
    navigateToDynamicDashboard() {
      this.$router.push(`/settings/ecosystem-heartbeat?community=${this.communityId}`)
    },
    async loadContent() {
      try {
        await this.$store.dispatch(COMMUNITY_ACTION_TYPES.FETCH_COMMUNITY_DETAIL, Number(this.communityId))

        this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, this.community.portfolioId)
      } catch (exception) {
        console.log(exception)

        return this.$router.push('/communities')
      }

      if (this.community && this.community.color && !this.$store.state.communities.detail.loading) {
        this.setCommunityColor(this.community.color)
      }

      this.fetchLatestMembers()
    },
    displayAddMemberModal() {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        community: {
          name: this.community.name,
          id: this.communityId,
        },
      })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_MEMBER_TO_COMMUNITY_MODAL)
    },
    async fetchLatestMembers() {
      this.latestMembers.isLoading = true

      fetchLatestMembers({ communityId: this.communityId })
          .then(items => {
            this.latestMembers.loading = false
            this.latestMembers.data = items
          })
          .catch(error => {
            this.latestMembers.loading = false
            console.log(error)
          })
    },
    setCommunityColor(color) {
      const communityDark = mix(0.9, color, '#000')
      const communityDarker = mix(0.75, color, '#000')
      const communityDarkest = mix(0.5, color, '#000')
      const communityExtraDarkest = mix(0.25, color, '#000')
      const communityLight = mix(0.9 * 100 / 255, color, '#fff')
      const communityLighter = mix(0.75 * 100 / 255, color, '#fff')
      const communityLightest = mix(0.5 * 100 / 255, color, '#fff')
      const communityExtraLightest = mix(0.25 * 100 / 255, color, '#fff')
      document.documentElement.style.setProperty('--primary-community', color)
      document.documentElement.style.setProperty('--primary-community-dark', communityDark)
      document.documentElement.style.setProperty('--primary-community-darker', communityDarker)
      document.documentElement.style.setProperty('--primary-community-darkest', communityDarkest)
      document.documentElement.style.setProperty('--primary-community-extra-darkest', communityExtraDarkest)
      document.documentElement.style.setProperty('--primary-community-light', communityLight)
      document.documentElement.style.setProperty('--primary-community-lighter', communityLighter)
      document.documentElement.style.setProperty('--primary-community-lightest', communityLightest)
      document.documentElement.style.setProperty('--primary-community-extra-lightest', communityExtraLightest)
    },
    startIntroJs() {
      if (this.seenIntros.includes('communitiesDetailPage')) {
        return
      }
      const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
      if (!config || !config.communitiesDetailPage || !config.communitiesDetailPage.steps) {
        return
      }

      config.communitiesDetailPage.steps = this.getIntroJsStepsConfig(config.communitiesDetailPage.steps)
      const intro = this.introJs().setOptions(config.communitiesDetailPage)
      this.currentIntro = intro

      const componentScope = this
      intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
      intro.onafterchange(this.introJsAfterStepCallback.bind(this))
      intro.oncomplete(function () {
        componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
      })
      intro.onexit(function () {
        componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
      })

      intro.start()
    },
  },
  async mounted() {
    await this.loadContent()

    // Enforce the correct route based on the setting of announcements and events
    if (['announcements', 'events'].includes(this.subNavSection) && !this.$route.params.id && !this.hasAccessToAnnouncementsAndEventsForCommunities) {
      this.$router.push('/communities/' + this.$route.params.communityId)
    }

    // this setTimeout is used because the startIntrojs loaded faster than the content on the page when we use clickAfter
    setTimeout(() => {
      this.startIntroJs()
    })
  },
  created() {
    this.$bus.on('invited-community-member', () => {
      this.fetchLatestMembers()
    })
  },
  beforeUnmount() {
    this.$bus.off('invited-community-member')
    this.$bus.off('announcementCreated')

    this.$store.dispatch(COMMUNITY_ACTION_TYPES.RESET_COMMUNITY_DETAIL)
    this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
    this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
    this.$store.commit(NOTIFICATION_MUTATION_TYPES.RESET_ACTIVE_DEFAULT_FILTER)
    this.$store.commit(NOTIFICATION_MUTATION_TYPES.RESET_ACTIVE_TAGS)
    this.setCommunityColor(this.$store.state.config.primaryColor)
  },
  mixins: [TranslationsMixin, IntroJsMixin, CommunityMixin]
})
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.page-community-detail {
  height: 100%;
  display: flex;
  flex-direction: column;

  .no-claim-badge {
    background: #dc3545;
    border: 2px solid #fbcaca;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    color: white;

    :deep(path) {
      fill: white;
    }

    .no-claim-badge-button {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .community-detail__sub-nav {
    .active {
      text-decoration: underline;
    }
  }

  h1.h1 {
    font-size: 26px;
    text-transform: uppercase;
    font-family: $font-stack-secondary;
    font-weight: 200;
  }
}

.filters {
  overflow: hidden;
  overflow-y: auto;
  padding: 1.5rem 2rem;

  @media (max-width: $screen-md) {
    padding: 1.5rem;
  }
}

.communities-members-link {
  text-decoration: none;
  display: block;

  &.has-action-buttons {
    width: calc(100% - 36px);
  }
}

.filters-title-container {
  display: flex;
  align-items: center;
}

.communities-detail__empty {
  font-size: 14px;
  text-align: center;
  line-height: 85px;
  margin: auto;
}
</style>
